const UPDATE_SHOW_DRAWER = (state, payload) => {
	state.drawer = payload;
};
const UPDATE_SHIFT = (state, payload) => {
	state.shift = payload;
};

export default {
	UPDATE_SHOW_DRAWER,
	UPDATE_SHIFT
};
