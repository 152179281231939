const componentType = (state) => state.componentType;
const series = (state) => state.series;
const shifts = (state) => state.shifts;
const results = (state) => state.results;
const spinning = (state) => state.spinning;
const status = (state) => state.status;

export default {
	componentType,
	series,
	shifts,
	results,
	spinning,
	status
};
