const VueAxios = {
	vm: {},
	/* eslint-disable */
	install(Vue, instance) {
		if (this.installed) {
			return;
		}
		this.installed = true;

		if (!instance) {
			// eslint-disable-next-line no-console
			return;
		}

		Vue.axios = instance;

		Object.defineProperties(Vue.prototype, {
			axios: {
				get: function get() {
					return instance;
				},
			},
			$http: {
				get: function get() {
					return instance;
				},
			},
		});
	},
};

export { VueAxios };
