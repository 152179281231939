const state = {
	loading: false,
	series: [],
	agentLeaders: [],
	agents: [],
	agentSpin: false,
	showBookPayments: [],
	companies: [],
	leaderId: 0,
	seryId: 0,
	isActiveCompany: 0,
	isActiveAgentLeader: 0,
	spinForm: false,
	allSeries: [],
};
export default state;
