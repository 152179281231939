const loading = (state) => state.loading;
const series = (state) => state.series;
const companies = (state) => state.companies;
const agnetleaders = (state) => state.agnetleaders;
const agents = (state) => state.agents;

export default {
	loading,
	series,
	companies,
	agnetleaders,
	agents
};
