const loading = (state) => state.loading;
const agents = (state) => state.agents;
const series = (state) => state.series;
const series_shifts = (state) => state.series_shifts;

export default {
	loading,
	agents,
	series,
	series_shifts
};
