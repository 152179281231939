import state from "./state";

const UPDATE_LOADING = (state, payload) => {
	state.loading = payload;
};

const UPDATE_SERIES = (state, data) => {
	state.series = data;
};

const UPDATE_AGENTS = (state, data) => {
	state.agents = data;
};

const UPDATE_PAPPER = (state, data) => {
	state.papers = data;
}

const UPDATE_IS_CHECKED = (state, data) => {
	state.checkedId = data;
}

const UPDATE_TOTAL_PAPERS = (state, data) => {
	state.totalPapers = data;
}

const UPDATE_PAPER_LOADING = (state, data) => {
	state.loadingPaper = data;
}

const UPDATE_LOTTERY_TIME = (state, data) => {
	state.shifts = data;
}

export default {
	UPDATE_LOADING,
	UPDATE_SERIES,
	UPDATE_AGENTS,
	UPDATE_PAPPER,
	UPDATE_IS_CHECKED,
	UPDATE_TOTAL_PAPERS,
	UPDATE_PAPER_LOADING,
	UPDATE_LOTTERY_TIME
};
