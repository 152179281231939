const loading = (state) => state.loading;
const getShift = (state) => state.shift;
const showDrawer = (state) => state.loading;
const getAllLotProviders = (state) => state.showAllLotProviders;
const getLotteryProvidersCycles = (state) => state.showLotteryProvidersCycles;
const getAllLotteryTimeType = (state) => state.showLotteryTimeTypes;
const getAllLotteryPostDetails = (state) => state.showLotteryPostDetails;
const getProName = (state) => state.showProviderName;

export default {
	loading,
	getShift,
	showDrawer,
	getAllLotProviders,
	getLotteryProvidersCycles,
	getAllLotteryTimeType,
	getAllLotteryPostDetails,
	getProName
};
