import request from '@/utils/request';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';
import router from '@/router';

const fetchSeries = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}book-payments/series`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_SERIES', response.data.all);
		commit('UPDATE_ALL_SERIES', response.data.checkes);
		commit('UPDATE_SERY_ID', 0);
		return response;
	});
};

const fetchAllAgentLeaders = ({ commit }) => {
	let query = router.currentRoute.query;
	let url = getQueryUrl(query);
	commit('UPDATE_AGENT_SPIN', true);
	return request({
		url: `${PRIVATE_API_PREFIX}book-payments/agent-leaders${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			commit('UPDATE_AGENT_LEADERS', response.data);
			commit('UPDATE_AGENT_LEADER_ID', 0);
			return response;
		})
		.finally(() => {
			commit('UPDATE_AGENT_SPIN', false);
		});
};

const fetchCompanies = ({ commit, dispatch }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}book-payments/company`,
		method: 'get',
		data: {},
	}).then((response) => {
		const { status, data } = response;
		if (status == 200) {
			commit('UPDATE_COMPANIES', data);
			commit('UPDATE_COMPANY_ID', 0);
		}
		return response;
	});
};

const actionGetOneAgentLeader = ({ commit }, payload) => {
	commit('UPDATE_AGENT_SPIN', true);
	let query = router.currentRoute.query;
	let url = getQueryUrl(query);
	const id = query.agent_leader_id ?? 0;
	return request({
		url: `${PRIVATE_API_PREFIX}book-payments/${id}${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			if (response.status === 200) {
				commit('UPDATE_AGENTS', response.data);
				commit('UPDATE_AGENT_SPIN', false);
				commit('UPDATE_LEADER_ID', 0);
			}
			return response;
		})
		.finally(() => {
			commit('UPDATE_AGENT_SPIN', false);
		});
};

const saveAgentBookPayments = ({ dispatch }, payload) => {
	const currentRoute = router.currentRoute;
	const id = currentRoute.params.id ? currentRoute.params.id : payload;
	if (id && payload) {
		return request({
			url: `${PRIVATE_API_PREFIX}book-payments/${id}`,
			method: 'post',
			data: {
				payload: JSON.stringify(payload.bookBookPayment),
				dates: JSON.stringify(payload.dates),
			},
		}).then((response) => {
			// dispatch("actionGetOneAgentLeader");
			return response;
		});
	}
};

const editAgentBookPayment = ({ commit }, payload) => {
	commit('UPDATE_LOADING', true);
	commit('UPDATE_SPIN_FORM', true);
	const currentRoute = router.currentRoute;
	const url = getQueryUrl(currentRoute.query);
	const id = currentRoute.params.id ? currentRoute.params.id : payload;
	if (id && payload) {
		return request({
			url: `${PRIVATE_API_PREFIX}book-payments/${payload}/bookpayments${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				commit('UPDATE_AGENT_BOOKPAYMENT', response.data);
				commit('UPDATE_SPIN_FORM', false);
				commit('UPDATE_LOADING', false);
				return response.data;
			})
			.finally(() => {
				commit('UPDATE_SPIN_FORM', false);
				commit('UPDATE_LOADING', false);
			});
	} else {
		commit('UPDATE_SPIN_FORM', false);
		commit('UPDATE_LOADING', false);
	}
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchSeries,
	fetchAllAgentLeaders,
	actionGetOneAgentLeader,
	saveAgentBookPayments,
	editAgentBookPayment,
	fetchCompanies,
};
