const users = (state) => state.users;
const isShowModal = (state) => state.showModal;
const user = (state) => state.user;
const drawerVisable = (state) => state.drawerVisable;
const userTypes = (state) => state.userTypes;
const genders = (state) => state.genders;
const roles = (state) => state.roles;
const statuses = (state) => state.statuses;
const groupWorks = (state) => state.groupWorks;
const commissions = (state) => state.commissions;
const prizes = (state) => state.prizes;
const agentLeaders = (state) => state.agentLeaders;
const userRoleType = (state) => state.userRoleType;
const userSpinning = (state) => state.userSpinning;
const companies = (state) => state.companies;
const eliUsers = (state) => state.eliUsers;
const eligibleSpin = (state) => state.eligibleSpin;
const totalUser = (state) => state.totalUser;
const versionLoading = (state) => state.versionLoading;
const employees = (state) => state.employees;
const agents = (state) => state.agents;
const agentLeading = (state) => state.agentLeading;
const agentGraphData = (state) => state.agentGraphData;
const agentGraphNotes = (state) => state.agentGraphNotes;

export default {
	users,
	isShowModal,
	user,
	drawerVisable,
	userTypes,
	genders,
	roles,
	statuses,
	groupWorks,
	commissions,
	prizes,
	agentLeaders,
	userRoleType,
	userSpinning,
	companies,
	eliUsers,
	eligibleSpin,
	totalUser,
	versionLoading,
	employees,
	agents,
	agentLeading,
	agentGraphData,
	agentGraphNotes
};
