import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetchUsers = ({ commit }, payload) => {
	commit('UPDATE_USER_SPIN', true);
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);
	if (url) {
		return request({
			url: `${PRIVATE_API_PREFIX}agent-reports/users${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				if (response.status === 200) {
					commit('UPDATE_USER_SPIN', false);
					commit('UPDATE_USER', response.data);
				}
			})
			.finally(() => {
				commit('UPDATE_USER_SPIN', false);
			});
	} else {
		commit('UPDATE_USER_SPIN', false);
	}
};

const fetchAgentLeaders = ({ commit }, payload) => {
	const route = router.currentRoute;
	const url = payload
		? `?company=${payload}`
		: route.query.company != undefined
		? `?company=${route.query.company}`
		: '';

	return request({
		url: `${PRIVATE_API_PREFIX}agent-reports/agent-leaders${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_AGENT_LEADER', response.data);
		}
		return response;
	});
};

const fetchCompanies = ({ commit }, payload) => {
	const route = router.currentRoute;

	return request({
		url: `${PRIVATE_API_PREFIX}agent-reports/companies`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_COMPANY', response.data);
		}
	});
};

const fetchReports = ({ commit }, payload) => {
	commit('UPDATE_MEMBER_SPIN', true);
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);
	const { id } = route.params;
	if (id) {
		return request({
			url: `${PRIVATE_API_PREFIX}agent-reports/${id}/reports${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				if (response.status === 200) {
					commit('UPDATE_REPORT', response.data);
					commit('UPDATE_MEMBER_SPIN', false);
				}
			})
			.finally(() => {
				commit('UPDATE_MEMBER_SPIN', false);
			});
	} else {
		commit('UPDATE_MEMBER_SPIN', false);
	}
};

const fetchSeries = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}agent-reports/series`,
		method: 'get',
		data: {},
	}).then((response) => {
		const { data, status } = response;
		if (status === 200) {
			commit('UPDATE_SEIRES', data);
		}
	});
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchUsers,
	fetchAgentLeaders,
	fetchCompanies,
	fetchReports,
	fetchSeries
};
