const UPDATE_LOADING = (state, payload) => {
	state.loading = payload;
};
const UPDATE_SHOW_DRAWER = (state, payload) => {
	state.loading = payload;
};
const UPDATE_SHIFT = (state, payload) => {
	state.shift = payload;
};
const UPDATE_LOTTERY_PROVIDERS = (state, payload) => {
	state.showAllLotProviders = payload;
};
const UPDATE_LOTTERY_PROVIDERS_CYCLES = (state, payload) => {
	state.showLotteryProvidersCycles = payload;
};
const UPDATE_LOTTERY_TIME_TYPES = (state, payload) => {
	state.showLotteryTimeTypes = payload;
};
const UPDATE_LOTTERY_POST_DETAILS = (state, payload) => {
	state.showLotteryPostDetails = payload;
};

const UPDATE_COMPONENT = (state, payload) => {
	state.componentName = `component_${payload}`;
};
const UPDATE_PROVIDERS = (state, payload) => {
	state.showProviderName = payload;
};
const UPDATE_SPINING = (state, payload) => {
	state.isSpining = payload;
};
const UPDATE_PROVIDER_SPINING = (state, payload) => {
	state.isProviderLoaded = payload;
};
const UPDATE_PROVIDER_DETAIL_SPINING = (state, payload) => {
	state.isProviderDetailLoaded = payload;
};
const UPDATE_WIDTH_DRAWER = (state, payload) => {
	state.isOpen = payload;
};

export default {
	UPDATE_LOADING,
	UPDATE_SHOW_DRAWER,
	UPDATE_SHIFT,
	UPDATE_LOTTERY_PROVIDERS,
	UPDATE_LOTTERY_PROVIDERS_CYCLES,
	UPDATE_LOTTERY_TIME_TYPES,
	UPDATE_LOTTERY_POST_DETAILS,
	UPDATE_COMPONENT,
	UPDATE_PROVIDERS,
	UPDATE_SPINING,
	UPDATE_WIDTH_DRAWER,
	UPDATE_PROVIDER_SPINING,
	UPDATE_PROVIDER_DETAIL_SPINING
};
