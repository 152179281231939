const UPDATE_LOADING = (state, payload) => {
	state.loading = payload;
};

const UPDATE_COMPANIES = (state, payload) => {
	state.companies = payload;
};

const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
};

const UPDATE_AGENT_LEADER= (state, payload) => {
	state.agnetleaders = payload;
};

const UPDATE_AGENT= (state, payload) => {
	state.agents = payload;
};

export default {
	UPDATE_LOADING,
	UPDATE_SERIES,
	UPDATE_COMPANIES,
	UPDATE_AGENT_LEADER,
	UPDATE_AGENT
};
