import label from './label.json'
import menu from './menu.json'
import table from './table.json'
import action from './action.json'
import message from './message.json'
import button from './button.json'
import placeholder from './placeholder.json'
import title from './title.json'
import validation from './validation.json'

export default {
    label,
    menu,
    table,
    action,
    message,
    button,
    placeholder,
    title,
    validation
}