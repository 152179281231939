const UPDATE_COMPONENT_TYPE = (state, payload) => {
	state.componentType = payload;
};
const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
};
const UPDATE_SHIFT_SERIES = (state, payload) => {
	state.shifts = payload;
};
const UPDATE_SERIES_RESULTS = (state, payload) => {
	state.results = payload;
};
const UPDATE_SERIES_RESULTS_DATE = (state, payload) => {
	state.showDate = payload;
};
const UPDATE_SPIN = (state, payload) => {
	state.spin = payload;
};
const UPDATE_SPINNING = (state, payload) => {
	state.spinning = payload;
};
const UPDATE_SERIES_NAME = (state, payload) => {
	state.seriesName = payload;
};
const UPDATE_SERIES_SHIFT = (state, payload) => {
	state.seriesShift = payload;
};

const UPDATE_SERIES_SPING = (state, payload) => {
	state.isSeriesLoading = payload;
};
const UPDATE_COMPONENT = (state, payload) => {
	setTimeout(() => {
		state.componentName = `component_${payload}`;
	}, 300);
};

export default {
	UPDATE_COMPONENT_TYPE,
	UPDATE_SERIES,
	UPDATE_SHIFT_SERIES,
	UPDATE_SERIES_RESULTS,
	UPDATE_SERIES_RESULTS_DATE,
	UPDATE_SPIN,
	UPDATE_SPINNING,
	UPDATE_SERIES_NAME,
	UPDATE_SERIES_SHIFT,
	UPDATE_SERIES_SPING,
	UPDATE_COMPONENT
};
