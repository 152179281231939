import request from '@/utils/request';
import authReq from '@/utils/auth_request';

import {
	PUBLIC_API_PREFIX,
	PRIVATE_API_PREFIX,
	BASE_URL,
} from '@/utils/api_prefix';

const login = ({ commit }, payload) => {
	return authReq({
		url: `${BASE_URL + PUBLIC_API_PREFIX}login`,
		method: 'post',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_AUTH', response.user);
		}
		return response;
	});
};

const logout = ({ commit }, payload) => {
	return authReq({
		url: `${PRIVATE_API_PREFIX}logout`,
		method: 'post',
		data: payload,
	})
		.then((response) => {
			return response;
		})
		.catch((errors) => {
			return errors;
		});
};

const register = ({ commit }, payload) => {
	return request({
		url: `${PUBLIC_API_PREFIX}register`,
		method: 'post',
		data: payload,
	}).then((result) => {
		return result;
	});
};

const getAuth = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}auth`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_AUTH', response.data);
		}
		return response;
	});
};

const fetchMenus = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}menus`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_MENU', response.data);
		}
	});
};

const autoUpload = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}upload-papers/transfer-papers`,
		method: 'post',
		data: payload,
	});
};

const fetchAllMenus = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}menus/lists`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_ALL_MENU', response.data);
		}
		return response;
	});
};

const queueCalcPaperBalance = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}queues`,
		method: 'post',
		data: payload,
	});
};

const fetchReportProgress = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}agent-reports/progress-reports`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_PROGRESS_REPORT', response);
	});
};

export default {
	login,
	logout,
	register,
	getAuth,
	fetchMenus,
	fetchAllMenus,
	queueCalcPaperBalance,
	fetchReportProgress,
	autoUpload,
};
