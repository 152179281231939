const auth = (state) => state.auth;
const isLoggedIn = (state) => state.isLogin;
const toggleCollapseMenu = (state) => state.isCollapse;
const openKey = (state) => state.openKey;
const spin = (state) => state.spin;
const menus = (state) => state.menus;
const allMenus = (state) => state.allMenus;
const currentRouteName = (state) => state.currentRouteName;
const progressReport = (state) => state.progressReport;
const allowProgressRoutes = (state) => state.allowProgressRoutes;

export default {
	auth,
	isLoggedIn,
    toggleCollapseMenu,
	openKey,
	spin,
	menus,
	allMenus,
	currentRouteName,
	progressReport,
	allowProgressRoutes
};
