import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetUsers = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	let url = '';
	if (query.page) {
		url = '?page=' + query.page;
	}

	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	}).then((result) => {
		if (result.status === 200) {
			commit('UPDATE_USER', result.data);
			commit('UPDATE_PAGINATION', result.meta);
		}
		return result;
	});
};

const fetchSeries = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}column-entries/series`,
		method: 'get',
		date: {},
	}).then((response) => {
		if (response.status == 200) {
			commit('UPDATE_SERIES', response.data);
		}
		return response;
	});
};

const fetchShifts = ({ commit, dispatch }) => {
	const route = router.currentRoute;
	let payload = route.query.sery;
	if (payload == '' || payload == undefined) {
		payload = 0;
	}

	return request({
		url: `${PRIVATE_API_PREFIX}column-entries/${payload}/shifts`,
		method: 'get',
		date: {},
	}).then((response) => {
		if (response.status == 200) {
			commit('UPDATE_SHIFTS', response.data);
		}
		return response;
	});
};

const fetchAgents = ({ commit, dispatch }) => {
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);
	
    if(route.query.shift){
		return request({
			url: `${PRIVATE_API_PREFIX}column-entries/agents${url}`,
			method: 'get',
			data: {},
		}).then((response) => {
			if (response.status == 200) {
				commit('UPDATE_AGENTS', response.data);
			}
			return response;
		});
	}
};

const fetchPapers = ({ commit }, payload) => {
	const route = router.currentRoute;
	let agent = router.currentRoute.params.id;
	if (!agent) {
		agent = 0;
	}
	if (!payload) {
		payload = agent;
	}
	const url = getQueryUrl(route.query);
	return request({
		url: `${PRIVATE_API_PREFIX}column-entries/${payload}/papers${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status == 200) {
			commit('UPDATE_PAPERS', response.data);
			commit('UPDATE_TOTAL_PAPERS', response.data.length);
		}

		if (!response.data?.length) {
			commit('UPDATE_IS_VIEWER', false);
		} else {
			commit('UPDATE_IS_VIEWER', true);
		}
		return response;
	});
};

const fetchCurrencies = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}column-entries/currrencies`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_CURRENCIES', response.data);
			commit('UPDATE_COLUMNS', response.data);
			commit('UPDATE_DEFAULT_COLUMN', response.data);
		}
		return response;
	});
};

const storeColumnEntry = ({commit, dispatch }, payload) => {
	let paperId = router.currentRoute.query.paper;
	// let dataContainer = [];
	commit('UPDATE_READY', false)

	const { columns, removeable } = payload;

	const filtered = columns.filter((col)=>{
		if (
					col[0].value.toString().replaceAll(',','') > 0 ||
					col[1].value.toString().replaceAll(',','') > 0 ||
					col[2].value.toString().replaceAll(',','') > 0 ||
					col[3].value.toString().replaceAll(',','') > 0
				) {
					return col
				}
	})
	return request({
		url: `${PRIVATE_API_PREFIX}column-entries`,
		method: 'post',
		data: {
			paperId: JSON.stringify(paperId),
			payload: JSON.stringify(filtered),
			removeable: JSON.stringify(removeable),
		},
	}).then((response) => {
		// dispatch('fetchColumnAmount');
		return response;
	});
};

const fetchColumnAmount = ({ commit }) => {
	const route = router.currentRoute;
  const url = getQueryUrl(route.query);
	return request({
		url: `${PRIVATE_API_PREFIX}column-entries/column-amounts${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status == 200) {
			let i = 0
			for (let col of response.data) {
				response.data[i][0].value =  currencyFormat(col[0].value);
				response.data[i][1].value =  currencyFormat(col[1].value);
				response.data[i][2].value =  currencyFormat(col[2].value);
				response.data[i][3].value =  currencyFormat(col[3].value);
				i++
			}
			if(response.data.length < 1 ){
				let data = [[
					{
						id: 0,
						currency_id: 1,
						currency_labels: "រៀល",
						value: "",
					},
					{
						id: 0,
						currency_id: 2,
						currency_labels: "ដុល្លា​",
						value: "",
					},
					{
						id: 0,
						currency_id: 3,
						currency_labels: "បាត",
						value: "",
					},
					{
						id: 0,
						currency_id: 4,
						currency_labels: "ដុង",
						value: "",
					},
				]]
				commit("UPDATE_COLUMN_AMOUNT",data);
				commit('UPDATE_READY', true)
				return response;
			}
			commit('UPDATE_COLUMN_AMOUNT', response.data);
			commit('UPDATE_READY', true)
		}
		return response;
	});
};
const fetchPaperNumber = ({ commit }) => {
    commit('UPDATE_NUMBER_SPIN', true);
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);
    const { id } = route.params;
    if(id){
        return request({
            url: `${PRIVATE_API_PREFIX}number-entries/${id}/lists${url}`,
            method: 'get',
            data: {},
        })
        .then((response) => {
            if(response.status == 200){
                commit('UPDATE_NUMBER_LIST', response.data);
            }
            commit('UPDATE_NUMBER_SPIN', false);
        })
        .finally(() => {
            commit('UPDATE_NUMBER_SPIN', false);
        });
    } else 
        commit('UPDATE_NUMBER_SPIN', false);
    
};




function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}
function currencyFormat(val){
	if(!val){
		return 0
	}
	return val.toLocaleString('en-US');
}

export default {
	fetUsers,
	fetchSeries,
	fetchShifts,
	fetchAgents,
	fetchPapers,
	fetchCurrencies,
	storeColumnEntry,
	fetchColumnAmount,
	fetchPaperNumber
};
