const state = {
	component: 'single',
	agentLeaders: [],
	agentLeaderSpin: false,
	agentLeaderName: null,
	startDate: null,
	endDate: null,
	reports: [],
	reportSpin: false,
	companies: [],
	companyName: null,
	totalReports: [],
	series: []
};

export default state;
