import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/admin/login',
		meta: {
			requiresAuth: false,
			title: 'Login',
			id: 1
		},
	},
	{
		path: '/admin/dashboard',
		name: 'admin.dashboard',
		meta: {
			requiresAuth: true,
			title: 'Dashboard',
			id: 2
		},
		component: () =>
			import(/* webpackChunkName: "dashboard" */ '../views/modules/dashboard'),
	},
	{
		path: '/admin/users/:id?',
		name: 'admin.user',
		meta: {
			requiresAuth: true,
			title: 'User',
			id: 3
		},
		component: () =>
			import(/* webpackChunkName: "user" */ '../views/modules/user'),
	},
	{
		path: '/admin/companies/:id?',
		name: 'admin.company',
		meta: {
			requiresAuth: true,
			title: 'Company',
			id: 4
		},
		component: () =>
			import(/* webpackChunkName: "company" */ '../views/modules/company'),
	},
	{
		path: '/admin/agents/:id?',
		name: 'admin.agent',
		meta: {
			requiresAuth: true,
			title: 'Agent leader',
			id: 5
		},
		component: () =>
			import(/* webpackChunkName: "agent" */ '../views/modules/agents'),
	},
	{
		path: '/admin/group-lines/:id?',
		name: 'admin.group_line',
		meta: {
			requiresAuth: true,
			title: 'Group line',
			id: 6
		},
		component: () =>
			import(/* webpackChunkName: "Group line" */ '../views/modules/group_line'),
	},
	{
		path: '/admin/papers/:id?',
		name: 'admin.paper',
		meta: {
			requiresAuth: true,
			title: 'Paper',
			id: 7
		},
		component: () =>
			import(/* webpackChunkName: "paper" */ '../views/modules/paper'),
	},
	{
		path: '/admin/verify-numbers/:id?',
		name: 'admin.verify.number',
		meta: {
			requiresAuth: true,
			title: 'Verify number',
			id: 8
		},
		component: () =>
			import(/* webpackChunkName: "verify number" */ '../views/modules/verify_number'),
	},
	{
		path: '/admin/entry-series/:id?',
		name: 'admin.entry.sery',
		meta: {
			requiresAuth: true,
			title: 'Entry series',
			id: 9
		},
		component: () =>
			import(/* webpackChunkName: "entry sery" */ '../views/modules/entry_sery'),
	},
	{
		path: '/admin/agent-book-payments/:id?',
		name: 'admin.agent.leader.book.payment',
		meta: {
			requiresAuth: true,
			title: 'Agent leader book payment',
			id: 10
		},
		component: () =>
			import(/* webpackChunkName: "agent leader payment" */ '../views/modules/agent_leader_book_payment'),
	},
	{
		path: '/admin/lot-managements/:id?',
		name: 'admin.lot.management',
		meta: {
			requiresAuth: true,
			title: 'Lottery management',
			id: 11
		},
		component: () =>
			import(/* webpackChunkName: "lottery management" */ '../views/modules/lottery_management'),
	},
	{
		path: '/admin/daily-company-results/:id?',
		name: 'admin.daily.company.result',
		meta: {
			requiresAuth: true,
			title: 'Daily company result',
			id: 12
		},
		component: () =>
			import(/* webpackChunkName: "daily company result" */ '../views/modules/daily_company_result'),
	},
	{
		path: '/admin/daily-agent-results/:id?',
		name: 'admin.daily.agent.result',
		meta: {
			requiresAuth: true,
			title: 'Daily agent result',
			id: 13
		},
		component: () =>
			import(/* webpackChunkName: "daily agent result" */ '../views/modules/daily_agent_result'),
	},
	{
		path: '/admin/company-book-payments/:id?',
		name: 'admin.company.book.payment',
		meta: {
			requiresAuth: true,
			title: 'Company book payment',
			id: 14
		},  
		component: () =>
			import(/* webpackChunkName: "company book payment" */ '../views/modules/company_book_payments'),
	},
	{
		path: '/admin/group-works/:id?',
		name: 'admin.group.work',
		meta: {
			requiresAuth: true,
			title: 'Group work',
			id: 15
		},
		component: () =>
			import(/* webpackChunkName: "work groups" */ '../views/modules/work_groups'),
	},
	{
		path: '/admin/column-entries/:id?',
		name: 'admin.column.entry',
		meta: {
			requiresAuth: true,
			title: 'Column entry',
			id: 16
		},
		component: () =>
			import(/* webpackChunkName: "column entry" */ '../views/modules/column_entry'),
	},
	{
		path: '/admin/number-entries/:id?',
		name: 'admin.number.entry',
		meta: {
			requiresAuth: true,
			title: 'Number entry',
			id: 17
		},
		component: () =>
			import(/* webpackChunkName: "number entry" */ '../views/modules/entry_number'),
	},
	{
		path: '/admin/check-faults/:id?',
		name: 'admin.check.fault',
		meta: {
			requiresAuth: true,
			title: 'Check fault',
			id: 18
		},
		component: () =>
			import(/* webpackChunkName: "check fault" */ '../views/modules/check_fault'),
	},
	{
		path: '/admin/upload-papers',
		name: 'admin.upload.paper',
		meta: {
			requiresAuth: true,
			title: 'Upload paper',
			id: 19
		},
		component: () =>
			import(/* webpackChunkName: "upload paper" */ '../views/modules/upload_paper'),
	},
	{
		path: '/admin/jub-runs',
		name: 'admin.job.run',
		meta: {
			requiresAuth: true,
			title: 'Job run',
			id: 20
		},
		component: () =>
			import(/* webpackChunkName: "job run" */ '../views/modules/job_run'),
	},
	{
		path: '/admin/salary/:id?',
		name: 'admin.salary',
		meta: {
			requiresAuth: true,
			title: 'Salary',
			id: 21
		},
		component: () =>
			import(/* webpackChunkName: "salary" */ '../views/modules/salary'),
	},
	{
		path: '/admin/reference-lists',
		name: 'admin.reference.list',
		meta: {
			requiresAuth: true,
			title: 'Reference list',
			id: 22
		},
		component: () =>
			import(/* webpackChunkName: "reference list" */ '../views/modules/reference_list'),
	},
	{
		path: '/admin/agent-leader-report/:id?',
		name: 'admin.agent.leader.report',
		meta: {
			requiresAuth: true,
			title: 'Agent Leader Report',
			id: 23
		},
		component: () =>
			import(/* webpackChunkName: "reference list" */ '../views/modules/agent_leader_report'),
	},
	{
		path: '/admin/company-report/:id?',
		name: 'admin.company.report',
		meta: {
			requiresAuth: true,
			title: 'Company Report',
			id: 24
		},
		component: () =>
			import(/* webpackChunkName: "reference list" */ '../views/modules/company_final_report'),
	},
	{
		path: '/admin/agent-booking-report/:id?',
		name: 'admin.agent.booking.report',
		meta: {
			requiresAuth: true,
			title: 'Agent Booking Report',
			id: 25
		},
		component: () =>
			import(/* webpackChunkName: "reference list" */ '../views/modules/agent_booking_report'),
	},
	{
		path: '/admin/login',
		name: 'admin.login',
		meta: {
			requiresAuth: false,
			title: 'Login',
			id: 25
		},
		component: () =>
			import(/* webpackChunkName: "login" */ '../views/auth/login'),
	},
	{
		path: '*',
		name: '404',
		meta: {
			requiresAuth: true,
			title: 'Not found',
			id: 26
		},
		component: () =>
			import(
				/* webpackChunkName: "404" */ '../views/exception/404.vue'
			),
	},
	{
		path: '/admin/403',
		name: '403',
		meta: {
			requiresAuth: true,
			title: 'Forbidden',
			id: 27
		},
		component: () =>
			import(
				/* webpackChunkName: "403" */ '../views/exception/403.vue'
			),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!Vue.$cookies.get('authenticated')) {
			next({ name: 'admin.login' });
		} else {
			next();
		}
		next();
	} else {
		next();
	}
});

export default router;
