const state = {
	loading: false,
	series: [],
	shifts: [],
	agents: [],
	papers: [],
	totals_papers: 0,
	betResult: [],
	formLoading: false,
	dateForm: "",
	dayForm : "",
	numbers: [],
	numberSpin: false
};

export default state;
