const state = {
	showForm: false,
	agents: {},
	series: {},
	lines: [],
	line: {
		id: 0,
		group_line_name: '',
		group_line_id: '',
		series_id: undefined,
		user_id: undefined,
		line_remark: '',
		status: 1,
	},
	amounts: [],
	line_id: 0,
	activeLineId: undefined,
	seriesName: ''
};

export default state;
