const UPDATE_COMPONENT = (state, payload) => {
	state.component = payload;
};

const UPDATE_USER = (state, payload) => {
	state.users = payload;
};

const UPDATE_USER_SPIN = (state, payload) => {
	state.userSpin = payload;
};

const UPDATE_COMPANY = (state, payload) => {
	state.companies = payload;
};

const UPDATE_AGENT_LEADER = (state, payload) => {
	state.agentLeaders = payload;
};

const UPDATE_AGENT_NAME = (state, payload) => {
	state.agentName = payload;
};

const UPDATE_FROM_DATE = (state, payload) => {
	state.fromDate = payload;
};

const UPDATE_TO_DATE = (state, payload) => {
	state.toDate = payload;
};

const UPDATE_REPORT = (state, payload) => {
	state.reports = payload;
};

const UPDATE_MEMBER_SPIN = (state, payload) => {
	state.memberSpin = payload;
};

const UPDATE_IS_TEAM = (state, payload) => {
	state.isTeam = payload;
};

const UPDATE_SEIRES = (state, payload) => {
	state.series = payload;
};

export default {
	UPDATE_COMPONENT,
	UPDATE_USER,
	UPDATE_USER_SPIN,
	UPDATE_COMPANY,
	UPDATE_AGENT_LEADER,
	UPDATE_AGENT_NAME,
	UPDATE_FROM_DATE,
	UPDATE_TO_DATE,
	UPDATE_REPORT,
	UPDATE_MEMBER_SPIN,
	UPDATE_IS_TEAM,
	UPDATE_SEIRES
};
