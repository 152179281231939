
const drawerVisible = (state) => state.drawerVisible;
const isCombine = (state) => state.isCombine;
const getGraph0 = (state)=>{
	return state.employees[0].graph
}

export default {
	drawerVisible,
	isCombine,
	getGraph0
};
