const state = {
    showPopup: true,
    lotteryType: "",
    posts: [],
    popularPosts: [],
    series: [],
    shifts: [],
    agents: [],
    papers: [],
    totals_papers: 0,
    activePaper: 0,
    datas: [],
    isViewer: false,
    showErrorPost: false,
    betType: "",
    betPattern : /^(:|^\*|^\-|^(x[1-6]?)|>|^(s[1-4]?[-+]?))$/gm,
    trans: [
        {
            id: '',
            is_empty: false,
            date: '',
            series_id: '',
            time_type_id: '',
            agent_id: '',
            paper_id: '',
            tables: [
                {
                    is_deleted: false,
                    post: "",
                    post_id: "",
                    id: "",
                    column_no: "",
                    order: "",
                    rows: [
                        {
                            is_deleted: false,
                            id: "",
                            number: "",
                            symbol: ":",
                            money: "",
                        },
                    ],
                },
            ],
        },
    ],
    numbers: [],
    numberSpin: false,
    betResults: [],
    // loadingForm: false,
    dateForm: '31-08-2022',
    dayForm: 'ច័ន្ទ',
    editable: true
};

export default state;
