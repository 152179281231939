import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetchAgentLeaders = ({ commit }, payload) => {
	commit('UPDATE_SPINING', true)
	const queries = router.currentRoute.query;
	const url = getQueryUrl(queries);
	const id = router.currentRoute.params.id
		? router.currentRoute.params.id
		: payload;
	
	return request({
		url: `${PRIVATE_API_PREFIX}company-book-payments/${id}/agent-leaders${url}`,
		method: 'get',
		data: {},
	}).then((result) => {
		if (result.status == 200) {
			commit('UPDATE_AGENT_LEADERS', result.data);
			commit('UPDATE_SPINING', false)
		}
		return result;
	}).finally(() => {
		commit('UPDATE_SPINING', false)
	})
};

const fetchAllSeries = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}company-book-payments/series`,
		method: 'get',
		data: {},
	}).then((result) => {
		if (result.status == 200) {
			commit('UPDATE_SERIES', result.data.all);
			commit('UPDATE_ALL_SERIES', result.data.series);
		}
		return result;
	});
};
const fetchAllCompanies = ({ commit}, payload) => {
	commit('UPDATE_SPINING', true)
	const queries = router.currentRoute.query;
	const url = getQueryUrl(queries);
	return request({
		url: `${PRIVATE_API_PREFIX}company-book-payments/companies${url}`,
		method: 'get',
		data: {},
	}).then((result) => {
		if (result.status == 200) {
			commit('UPDATE_ALL_COMPANIES', result.data);
		}
		return result;
	}).finally(()=>{
		commit('UPDATE_SPINING', false)
	})
};

const saveCompanyBookPayment = ({ dispatch }, payload) => {
	const { params } = router.currentRoute;
	return request({
		method: 'post',
		url: `${PRIVATE_API_PREFIX}company-book-payments/${params.id}/book-payments`,
		data: {
			company_id: JSON.stringify(payload.company_id),
			series_id: JSON.stringify(payload.series_id),
			dates: JSON.stringify(payload.dates),
			book_payments: JSON.stringify(payload.book_payments),
		},
	}).then((result) => {
		if (result.status == 200) {
			dispatch('fetchAllCompanyBookPayments', result.data);
		}
		return result;
	});
};
const fetchAllCompanyBookPayments = ({ commit }, payload) => {
	commit('UPDATE_LOADING', true)
	const query = router.currentRoute;
	const id = query.params.id ? query.params.id : payload;
	const url = getQueryUrl(query.query);
	if (id > 0 && query.query.series_id != undefined) {
		return request({
			url: `${PRIVATE_API_PREFIX}company-book-payments/${id}/book-payments${url}`,
			method: 'get',
			data: {},
		}).then((result) => {
			if (result.status == 200) {
				for (let book = 0; book < result.data.length; book++) {
					const element = result.data[book].rows;
					let i=0
					for(const {balance_in,balance_out, creditnote} of element){
							result.data[book].rows[i].balance_in=currencyFormat(balance_in)
							result.data[book].rows[i].balance_out=currencyFormat(balance_out)
							result.data[book].rows[i].creditnote=currencyFormat(creditnote)
							i++
					}
			}
				currencyFormat()
				commit('UPDATE_LOADING', false)
				commit('UPDATE_ALL_BOOKPAYMENTS', result.data);
			}

			return result;
		}).finally(()=>{
			commit('UPDATE_LOADING', false)
		});
	} else {
		commit('UPDATE_LOADING', false)
	}
};
function currencyFormat(val){
	if(!val){
		return null
	}
	return val.toLocaleString('en-US');
}

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchAgentLeaders,
	saveCompanyBookPayment,
	fetchAllSeries,
	fetchAllCompanies,
	fetchAllCompanyBookPayments,
};
