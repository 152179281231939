import request from '@/utils/request';
import {PRIVATE_API_PREFIX} from "@/utils/api_prefix";

const fetchUsers = ({ commit }, payload) => {
	return  request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	})
	.then((reponse) => {
		return reponse;
	});
};

export default {
	fetchUsers
};
