import request from '@/utils/request';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';
import router from '@/router';

const fetchUsers = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		return reponse;
	});
};
const fetchCompanies = ({ commit }) => {
	let param = router.currentRoute.query.param;
	let search = '';
	if (param) search += `?param=${param}`;
	return request({
		url: `${PRIVATE_API_PREFIX}companies${search}`,
		method: 'get',
	}).then((reponse) => {
		commit('UPDATE_COMPANIES', reponse.data);
	});
};

const fetchUserCompany = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}companies/users`,
		method: 'get',
	}).then((response) => {
		commit('UPDATE_USERS_OWNER', response.data);
	});
};

const createCompany = ({ dispatch }, { payload, checked }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}companies`,
		method: 'post',
		data: { payload, checked },
	}).then((response) => {
		dispatch('fetchCompanies');
		return response;
	});
};

const editCompany = ({ commit }, payload) => {
	const id = router.currentRoute.params.id;
	return request({
		url: `${PRIVATE_API_PREFIX}companies/${id}/edit`,
		method: 'get',
	}).then((response) => {
		commit('UPDATE_COMPANY', response.data);
		return response;
	});
};

const updateCompany = ({ dispatch }, { payload, agent, newVersion }) => {
	const id = router.currentRoute.params.id;
	return request({
		url: `${PRIVATE_API_PREFIX}companies/${id}`,
		method: 'put',
		data: { payload, agent, newVersion },
	}).then((response) => {
		dispatch('fetchCompanies');
		return response;
	});
};
const fetchAgentLeader = ({ commit }, payload) => {
	let param = router.currentRoute.query.text;
	let search = '';
	if (param) search += `?text=${param}`;

	return request({
		url: `${PRIVATE_API_PREFIX}companies/${payload}/agent-leaders${search}`,
		method: 'get',
	}).then((response) => {
		commit('UPDATE_AGENT_LEADER', response.data.agentByLeader);
		commit('UPDATE_AGENT_CHECKED', response.data.agentInCompany);
		return response;
	});
};
const freeAgentLeader = ({ commit }, payload) => {
	let param = router.currentRoute.query.text;
	let search = '';
	if (param) search += `?text=${param}`;

	return request({
		url: `${PRIVATE_API_PREFIX}companies/agent-leaders-nocompany${search}`,
		method: 'get',
	}).then((response) => {
		commit('UPDATE_AGENT_LEADER', response.data);
		return response;
	});
};

const fetchCompanyVersions = ({ commit }, payload) => {
	commit('UPDATE_MODAL_LOADING', true);
	return request({
		url: `${PRIVATE_API_PREFIX}companies/${payload}/version-histories`,
		method: 'get',
	}).then((response) => {
		const { status, data } = response;
		if(status == 200){
			commit('UPDATE_COMPANY_VERSION', data);
			commit('UPDATE_MODAL_LOADING', false);
		}
		return response;
	}).finally(() => {
		commit('UPDATE_MODAL_LOADING', false);
	})
};

export default {
	fetchUsers,
	fetchCompanies,
	fetchUserCompany,
	createCompany,
	editCompany,
	updateCompany,
	fetchAgentLeader,
	freeAgentLeader,
	fetchCompanyVersions
};
