const state = {
	series: [],
	agents: [],
	papers: [],
	checkedId: [],
	totalPapers: [],
	loadingPaper: false,
	shifts: []
};

export default state;
