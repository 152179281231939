import request from "@/utils/request";
import router from "@/router";
import { PRIVATE_API_PREFIX } from "@/utils/api_prefix";

const fetchUsers = ({ commit }) => {
    return request({
        url: `${PRIVATE_API_PREFIX}group-works/users`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("GET_USER", response.data);
    });
};

const fetchGroupWorks = ({ commit }) => {
    let url = "";
    const query = router.currentRoute.query;
    if (query.param !== undefined && query.param !== "")
        url += `?param=${query.param}`;
    return request({
        url: `${PRIVATE_API_PREFIX}group-works${url}`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_GROUPWORK", response.data);
    });
};

const registerGroupWork = ({ dispatch }, payload) => {
    return request({
        url: `${PRIVATE_API_PREFIX}group-works`,
        method: "post",
        data: payload,
    }).then((response) => {
        dispatch("fetchGroupWorks");
        return response;
    });
};

const actionGetGroupWork = ({ commit }, payload) => {
    return request({
        url: `${PRIVATE_API_PREFIX}group-works/${payload}/edit`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_TEAMS", response.data);
    });
};

const actionUpdteGroupWork = ({ dispatch }, payload) => {
    return request({
        url: `${PRIVATE_API_PREFIX}group-works/${payload.id}`,
        method: "put",
        data: payload,
    }).then((response) => {
        dispatch("fetchGroupWorks");
        return response;
    });
};

const actionGetGroupMember = ({ commit }, payload) => {
    return request({
        url: `${PRIVATE_API_PREFIX}group-works/${payload}/members`,
        method: "get",
        data: payload,
    }).then((response) => {
        commit("UPDATE_GROUPMEMER", response.data);
    });
};

export default {
    fetchUsers,
    registerGroupWork,
    fetchGroupWorks,
    actionGetGroupWork,
    actionUpdteGroupWork,
    actionGetGroupMember,
};
