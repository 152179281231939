const state = {
	componentType: 'kh',
	series: [],
	seriesID: 0,
	shifts: [],
	results: [],
	showDate: '',
	spinning: false,
	spin: false,
	seriesName: '',
	seriesShift: '',
	isSeriesLoading: false,
	componentName: ''
};

export default state;
