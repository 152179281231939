const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
};

const UPDATE_SUB_TOTAL = (state, payload) => {
	state.subTotal = payload;
};

const UPDATE_COMPANY_SPIN = (state, payload) => {
	state.companySpin = payload;
};
const UPDATE_COMPONENT = (state, payload) => {
	state.component = payload;
};

const UPDATE_COMPANIES = (state, payload) => {
	state.companies = payload;
};

const UPDATE_REPORT = (state, payload) => {
	state.reports = payload;
};

const UPDATE_REPORT_SPIN = (state, payload) => {
	state.reportSpin = payload;
};

const UPDATE_COMPANY_NAME = (state, payload) => {
	state.companyName = payload;
};

const UPDATE_REPORT_DATE = (state, payload) => {
	state.reportDate = payload;
};

const UPDATE_UPDATE_MODAL = (state, payload) => {
	state.modalVisable = payload;
};

const UPDATE_SERIES_ID = (state, payload) => {
	state.seriesId = payload;
};

const UPDATE_INCOME_EXPENSE = (state, payload) => {
	state.company = payload;
};

export default {
	UPDATE_COMPONENT,
	UPDATE_SERIES,
	UPDATE_COMPANY_SPIN,
	UPDATE_COMPONENT,
	UPDATE_COMPANIES,
	UPDATE_REPORT,
	UPDATE_REPORT_SPIN,
	UPDATE_COMPANY_NAME,
	UPDATE_REPORT_DATE,
	UPDATE_SUB_TOTAL,
	UPDATE_UPDATE_MODAL,
	UPDATE_SERIES_ID,
	UPDATE_INCOME_EXPENSE
};
