const state = {
	loading: false,
	isSpining: false,
	isProviderLoaded: false,
	isProviderDetailLoaded: false,
	shift: '',
	showAllLotProviders: [],
	showLotteryProvidersCycles: [],
	showLotteryPostDetails:[],
	showLotteryTimeTypes: [],
	isOpen:false,
	formLotteryProvider:
	{
		provider_name: "",
		provider_cycle_id: "",
		no_time_per_cycle: "",
		source_cycle_name: "",
		created_at: "",
		created_by: "",
	},
	componentName: '',
	showProviders:'',
	showProviderName:[]

};

export default state;
