import request from '@/utils/request';
import {PRIVATE_API_PREFIX} from "@/utils/api_prefix";
import router from '@/router';

const fetchUsers = ({ commit }, payload) => {
	return  request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	})
	.then((reponse) => {
		return reponse;
	});
};

const fetchSeries = ({ commit }) => {
	commit('UPDATE_SPIN', true)
	return request({
		url: `${PRIVATE_API_PREFIX}check-faults/series`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_SERIES', response.data);
		commit('UPDATE_SPIN', false)
	}).finally(()=> {
		commit('UPDATE_SPIN', false)
	})
}

const fetchUser = ({ commit, dispatch }, payload) => {
	commit('UPDATE_SPINNING', true)
	const params = router.currentRoute;
	const url = getQueryUrl(params.query);
	
	return request({
		url: `${PRIVATE_API_PREFIX}check-faults/users${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_USERS', response.data)
		commit('UPDATE_SPINNING', false)
	}).finally(() => {
		commit('UPDATE_SPINNING', false)
	})
}

const fetchAgentBySeries = ({ commit }, payload) => {
	commit('UPDATE_SPIN', true)
	const params = router.currentRoute;
	const userId = params.params.id;
	const url = getQueryUrl(params.query);
	if(url){
		return request({
			url: `${PRIVATE_API_PREFIX}check-faults/${userId}/agents${url}`,
			method: 'get',
			data: {},
		}).then((response) => {
			
			commit('UPDATE_SERIES_ITEM', response.data);
			commit('UPDATE_SERIES_NAME', response.data.series_name);
			commit('UPDATE_AGENT_BY_SERIES', response.data.users);
			commit('UPDATE_LOTTERY_BY_SERIES', response.data.series_shifts);
			commit('UPDATE_LOTTERY_TOTAL', response.data.sub_total);
			commit('UPDATE_SPIN', false)
		}).finally(() => {
			commit('UPDATE_SPIN', false)
		})
	} else {
		commit('UPDATE_SPIN', false)
	}
}

function getQueryUrl(queries){
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key,index) => {
		if(queries[key] != undefined){
			i+=1;
			if(i == 1){
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		} 
	})
	return url;
}

export default {
	fetchUsers,
	fetchSeries,
	fetchUser,
	fetchAgentBySeries
};
