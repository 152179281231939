<template>
	<div class="menu-wrapper">
		<collapse-menu v-if="$route.name != 'admin.dashboard'"></collapse-menu>
		<a-layout-sider
			v-model="toggleCollapseMenu"
			:trigger="null"
			collapsible
			:class="[
				!toggleCollapseMenu
					? 'menu-colapse-width'
					: 'menu-uncolapse-width',
			]"
		>
			<div class="w-full menu">
				<a-menu
					v-if="menus"
					:selected-keys.sync="openKey"
					mode="inline"
					theme="dark"
					class="h-full"
					:inline-collapsed="toggleCollapseMenu"
					@click="handleClick"
				>
					<template>
						<a-menu-item
							v-for="(menu, i) in menus"
							:key="i"
							:disabled="
								menu.param.route == currentRouteName ||
								(menu.param.route != currentRouteName &&
									disableMenu)
							"
							@click="gotoRoute(menu)"
						>
							<template v-if="menu.param">
								<fs-icon
									:type="menu.param.icon"
									:uniquekey="menu.param.icon"
								></fs-icon>
							</template>
							<span v-if="menu.param">
								{{ $t(menu.param.title) }}
							</span>
						</a-menu-item>
					</template>
				</a-menu>
			</div>

			<div class="menu-footer">
				<div class="menu-footer-profile">
					<div class="logout-template" @click="handleLogout()">
						<fs-icon type="ri:logout-circle-r-line"></fs-icon>
					</div>
					<a-avatar :size="56" :src="avatar" />
				</div>
			</div>
		</a-layout-sider>
	</div>
</template>

<script>
	import CollapseMenu from '@/views/layouts/collapse_menu';
	import router from '@/router';
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

	export default {
		name: 'SideBarLayout',
		components: {
			CollapseMenu,
		},
		data() {
			return {
				avatar: 'https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_960_720.png',
				selectedKeys: [],
			};
		},
		methods: {
			...mapActions(['fetchMenus', 'logout']),
			...mapMutations([
				'UPDATE_OPENKEY',
				'UPDATE_COLLAPSE_MENU',
				'UPDATE_CURRENT_MENU',
			]),
			gotoRoute(e) {
				this.UPDATE_CURRENT_MENU(e.param.route);
				if (!this.toggleCollapseMenu) this.UPDATE_COLLAPSE_MENU(true);
				router
					.push({
						name: e.param.route,
					})
					.catch(() => {});
				document.title = this.$t(e.title);
			},
			handleClick(e) {
				this.$cookies.set('openKey', e.keyPath);
				this.UPDATE_OPENKEY(e.keyPath);
			},
			handleLogout() {
				const vm = this;
				this.$confirm({
					title: 'Do you want to logout?',
					content: '',
					async onOk() {
						await vm.doLogout();
					},
					onCancel() {},
				});
			},
			async doLogout() {
				await this.logout().then((response) => {
					let cookies = this.$cookies.keys();
					if (cookies) {
						cookies.forEach((cookie) => {
							if (cookie != 'locale')
								this.$cookies.set(cookie, '');
						});
					}
					this.$router.push({ name: 'admin.login' }).catch(() => {});
				});
			},
		},
		created() {
			const { name } = this.$router.currentRoute;
			if (name) this.UPDATE_CURRENT_MENU(name);
		},
		mounted() {
			this.UPDATE_OPENKEY([JSON.parse(this.$cookies.get('openKey'))]);
			this.fetchMenus();
		},
		computed: {
			...mapGetters([
				'toggleCollapseMenu',
				'openKey',
				'menus',
				'currentRouteName',
			]),
			...mapState(['disableMenu']),
		},
	};
</script>
<style>
	.menu-footer {
		text-align: center;
		/* position: relative; */
	}
	.menu-footer-profile {
		position: relative;
	}
	.logout-template {
		position: absolute;
		transition: all 0.5s;
		z-index: 0;
		top: 14px;
		left: 5px;
		height: 50px;
		width: 50px;
		border-radius: 50%;
		background: #0e2938;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.menu-footer:hover .logout-template {
		z-index: 10;
		background: #f7685b;
	}
</style>
