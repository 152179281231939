import request from "@/utils/request";
import router from "@/router";
import { PRIVATE_API_PREFIX } from "@/utils/api_prefix";

const fetUsers = ({ commit }, payload) => {
    const { query } = router.currentRoute;
    let url = "";
    if (query.page) {
        url = "?page=" + query.page;
    }

    return request({
        url: `${PRIVATE_API_PREFIX}users${url}`,
        method: "get",
        data: {},
    }).then((result) => {
        if (result.status === 200) {
            commit("UPDATE_USER", result.data);
            commit("UPDATE_PAGINATION", result.meta);
        }
    });
};
// ==================

const fetchSeries = ({ commit }) => {
    return request({
        url: `${PRIVATE_API_PREFIX}number-entries/series`,
        method: "get",
        date: {},
    }).then((response) => {
        commit("UPDATE_SERIES", response.data);
        return response;
    });
};

const fetchShifts = ({ commit }, payload) => {
    // const route = router.currentRoute;
    // let payload = route.query.sery;
    // if (payload == "" || payload == undefined) {
    //     payload = 0;
    // }

    return request({
        url: `${PRIVATE_API_PREFIX}number-entries/${payload}/shifts`,
        method: "get",
        date: {},
    }).then((response) => {
        commit("UPDATE_SHIFTS", response.data);
        return response;
    });
};

const fetchAgents = ({ commit }) => {
    const route = router.currentRoute;
    const url = getQueryUrl(route.query);

    return request({
        url: `${PRIVATE_API_PREFIX}number-entries/agents${url}`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_AGENTS", response.data);
        return response;
    });
};
const fetchPosts = ({ commit }, payload) => {
    if(payload){
        return request({
            url: `${PRIVATE_API_PREFIX}number-entries/posts/${payload}`,
            method: "get",
            data: {},
        }).then((response) => {
            commit("UPDATE_POSTS", response.data);
        });
    } else {
        commit("UPDATE_POSTS", []);
    }
    
};
const fetchPopularPosts = ({ commit }, payload) => {
    // const route = router.currentRoute;
    // const url = getQueryUrl(route.query);
    // const { paper } = route.query;
    if(payload){
        return request({
            url: `${PRIVATE_API_PREFIX}number-entries/popular-posts/${payload}`,
            method: "get",
            data: {},
        }).then((response) => {
            commit("UPDATE_POPULAR_POSTS", response.data);
            // dispatch("fetchPapers");
        });
    } else {
        commit("UPDATE_POPULAR_POSTS", []);
    }
    
};
const fetchBetType = ({ commit }) => {
    return request({
        url: `${PRIVATE_API_PREFIX}number-entries/bettype`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_BETTYPE", response.data);
    });
};

const fetchPapers = ({ commit }, payload) => {
    const route = router.currentRoute;
    let agent = router.currentRoute.params.id;
    
    if (agent === undefined || agent === "") {
        agent = 0;
    }

    if (payload === undefined || payload == "") {
        payload = agent;
    }

    const url = getQueryUrl(route.query);

    return request({
        url: `${PRIVATE_API_PREFIX}number-entries/${payload}/papers${url}`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_PAPERS", response.data);
        return response;
    });
};

const fetchTrans = ({commit}) => {
    const route = router.currentRoute;
    const url = getQueryUrl(route.query);

    if(route.query.paper){
        return request({
            url: `${PRIVATE_API_PREFIX}number-entries?${url}`,
            method: "get",
            data: {},
        }).then((response) => {
            const { trans, editable } = response.data;
            commit("UPDATE_EDTABLE", editable);
            if(trans.length == 0){
                let def = [
                    {
                        edit_col: true,
                        is_empty: false,
                        date: '',
                        series_id: '',
                        time_type_id: '',
                        agent_id: '',
                        paper_id: '',
                        tables: [
                            {
                                is_deleted: false,
                                post: "",
                                post_id: "",
                                id: "",
                                column_no: "",
                                order: "",
                                rows: [
                                    {
                                        is_deleted: false,
                                        id: "",
                                        number: "",
                                        symbol: ":",
                                        money: "",
                                    },
                                ],
                            },
                        ],
                    },
                ]
                commit("UPDATE_TRANS", def);
                return response;
            } else{
                commit("UPDATE_TRANS", trans);
            }
        });
    } else {
        commit("UPDATE_EDTABLE", true);
    }
};
const storeTrans = ({dispatch}, {trans, header}) => {
    const route = router.currentRoute;
    const url = getQueryUrl(route.query);
    return request({
        url: `${PRIVATE_API_PREFIX}number-entries${url}`,
        method: "post",
        data: {trans, header},
    }).then((response) => {
        dispatch('fetchTrans');
        return response;
    });
};

const fetchPaperNumber = ({ commit }) => {
    commit('UPDATE_NUMBER_SPIN', true);
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);
    const { id } = route.params;
    if(id){
        return request({
            url: `${PRIVATE_API_PREFIX}number-entries/${id}/lists${url}`,
            method: 'get',
            data: {},
        })
        .then((response) => {
            if(response.status == 200){
                commit('UPDATE_NUMBER_LIST', response.data);
            }
            commit('UPDATE_NUMBER_SPIN', false);
        })
        .finally(() => {
            commit('UPDATE_NUMBER_SPIN', false);
        });
    } else 
        commit('UPDATE_NUMBER_SPIN', false);
    
};

function getQueryUrl(queries) {
    var url = "";
    var i = 0;
    Object.keys(queries).forEach((key, index) => {
        if (queries[key] != undefined) {
            i += 1;
            if (i == 1) {
                url += `?${key}=${queries[key]}`;
            } else {
                url += `&${key}=${queries[key]}`;
            }
        }
    });
    return url;
}

export default {
    fetUsers,
    fetchSeries,
    fetchShifts,
    fetchAgents,
    fetchPapers,
    fetchPosts,
    fetchPopularPosts,
    fetchBetType,
    storeTrans,
    fetchTrans,
    fetchPaperNumber
};
