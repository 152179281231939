const UPDATE_LOADING = (state, payload) => {
	state.loading = payload;
};

const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
};

const UPDATE_SHIFTS = (state, payload) => {
	state.shifts = payload;
};

const UPDATE_AGENTS = (state, payload) => {
	state.agents = payload;
};

const UPDATE_PAPERS = (state, payload) => {
	state.papers = payload;
};

const UPDATE_TOTAL_PAPERS = (state, payload) => {
	state.totals_papers = payload;
};

const UPDATE_BETRESULT = (state, payload) => {
	state.betResults = payload;
};

const UPDATE_FORM_SPINNING = (state, payload) => {
	state.formLoading = payload;
}

const UPDATE_DATE_FORM = (state, payload) => {
	state.dateForm = payload;
}
const UPDATE_NUMBER_LIST = (state, payload) => {
	state.numbers = payload;
}

const UPDATE_NUMBER_SPIN = (state, payload) => {
	state.numberSpin = payload;
}

const UPDATE_DAY_FORM = (state, payload) => {
	var dayName = '';
	switch (payload) {
		case 'Mo':
			dayName = 'ច័ន្ទ';
			break;
		case 'Tu':
			dayName = 'អង្គារ';
			break;
		case 'We':
			dayName = 'ពុធ';
			break;
		case 'Th':
			dayName = 'ព្រហស្បតិ៍';
			break;
		case 'Fr':
			dayName = 'សុក្រ';
			break;
		case 'Sa':
			dayName = 'សៅរ៍';
			break;
		case 'Su':
			dayName = 'អាទិត្យ';
			break;
	
		default:
			break;
	}
	state.dayForm = dayName;
}

export default {
	UPDATE_LOADING,
	UPDATE_SERIES,
	UPDATE_SHIFTS,
	UPDATE_AGENTS,
	UPDATE_PAPERS,
	UPDATE_TOTAL_PAPERS,
	UPDATE_BETRESULT,
	UPDATE_FORM_SPINNING,
	UPDATE_DATE_FORM,
	UPDATE_DAY_FORM,
	UPDATE_NUMBER_LIST,
	UPDATE_NUMBER_SPIN
};
