import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import state from './state';

function loadStores() {
	const context = require.context('./modules', true, /index.js$/i);
	return context
		.keys()
		.map(context)
		.map((m) => m.default);
}

const modules = {};
loadStores().forEach((resource) => {
    if(resource.namespaced == undefined) {
        resource.namespaced = true;
    }
	modules[resource.name] = resource;
});

Vue.use(Vuex);

export default new Vuex.Store({
	modules: modules,
	state,
	getters,
	mutations,
	actions,
});
