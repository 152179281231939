const state = {
	loading: false,
	series: [],
	users: [],
	spinning: false,
	agents: [],
	lottery:[],
	subTotal: 0,
	spin: false,
	seriesName: null,
	seriesItems: []
};

export default state;
