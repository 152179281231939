import notification from 'ant-design-vue/es/notification';
import moment from 'moment';
import { bus } from './../event.bus.js';

const mixins = {
	methods: {
		moment,
		displayValidateMessage: function (errors) {
			const response = errors.response.data.errors;
			Object.keys(response).map((field) => {
				this.errors[field] = response[field][0];
				// const validatArr = response[field][0].split('.');
				// this.errors[field] =
				// 	validatArr[0] + '.' + field + '.' + validatArr[1];
			});
		},
		antNotification: function (type, message, description, placement = 'topRight') {
			notification[type]({
				message: message ? message : '',
				description: description ? description : '',
				placement
			});
		},
		clearValidateError(errors) {
			Object.keys(errors).map((field) => {
				this.errors[field] = null;
			});
		},
		filterDropdown(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			);
		},
		initValidateMessage(errors, rules) {
			Object.keys(rules).map((field) => {
				errors[field] = rules[field][0].message;
			});
		},
		liveDateTime() {
			const date = new Date();
			let currentDate =
				date.getFullYear() +
				'-' +
				(date.getMonth() + 1) +
				'-' +
				date.getDate();
			let hours = date.getHours();
			let minutes = date.getMinutes();
			let seconds = date.getSeconds();
			hours = hours <= 9 ? `${hours}`.padStart(2, 0) : hours;
			minutes = minutes <= 9 ? `${minutes}`.padStart(2, 0) : minutes;
			seconds = seconds <= 9 ? `${seconds}`.padStart(2, 0) : seconds;
			return `${currentDate} ${hours}:${minutes}:${seconds}`;
		},
		handleFocus(event) {
			event.target.select();
			bus.$emit('clearKeyPress');
		},
		isValidSeries(event, limitLenth = null, pressNum, obj, field) {
			bus.$emit('increaseKeyPress');
			const value = event.target.value;
			const length = value.toString().length;
			const keysAllowed = [
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
			];
			const keyPressed = event.key;

			if (!keysAllowed.includes(keyPressed)) {
				return event.preventDefault();
			}
			// if (!limitLenth) {
			// 	if (!/\d/.test(event.key) && typeof event.key != 'number') {
			// 		return event.preventDefault();
			// 	}
			// } else {
			// 	if (
			// 		(!/\d/.test(event.key) && typeof event.key != 'number') ||
			// 		length >= limitLenth
			// 	) {
			// 		return event.preventDefault();
			// 	}
			// }

			if (pressNum == 0) {
				obj[field] = '';
				setTimeout(() => {
					obj[field] = event.key;
				}, 10);
			}

			
			if (length >= limitLenth) {
				return event.preventDefault();
			}
		},
		isValidDecimal(event, limitLenth, pressNum, obj, field){
			bus.$emit('increaseKeyPress');
			const value = event.target.value;
			const length = value.toString().length;
			const keysAllowed = [
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'.'
			];
			const keyPressed = event.key;

			// if(value == 0 && keyPressed != '.') {
			// 	// bus.$emit('increaseKeyPress');
			// 	return event.preventDefault();
			// }

			if (!keysAllowed.includes(keyPressed)) {
				return event.preventDefault();
			}

			if (pressNum == 0) {
				obj[field] = '';
				setTimeout(() => {
					obj[field] = event.key;
				}, 10);
			}
			
			if(value.includes('.')){
				const [a,b] = value.split('.');
				if(a.toString().length > limitLenth){
					return event.preventDefault();
				}
				if(b.toString().length >= 2){
					return event.preventDefault();
				}
			}
			
			if (!value.includes('.')) {
				if(length >= limitLenth && keyPressed != '.'){
					return event.preventDefault();
				}
			}

			const dot = value.split('.').length - 1;
	
			if(dot > 0 && keyPressed == '.'){
				return event.preventDefault();
			}
		},
		isValidFloat(event, limitLenth, pressNum, obj, field){
			bus.$emit('increaseKeyPress');
			const value = event.target.value;
			const length = value.toString().length;
			const keysAllowed = [
				'0',
				'1',
				'2',
				'3',
				'4',
				'5',
				'6',
				'7',
				'8',
				'9',
				'.',
				'-'
			];
			const keyPressed = event.key;
			if(value == 0 && length == 1 && keyPressed != '.') {
				// bus.$emit('increaseKeyPress');
				return event.preventDefault();
			}

			if(keyPressed == '.' && !value) {
				return event.preventDefault();
			}

			if(value == '-' && keyPressed == '.' ) {
				return event.preventDefault();
			}

			if (!keysAllowed.includes(keyPressed)) {
				return event.preventDefault();
			}

			if (pressNum == 0) {
				obj[field] = '';
				// setTimeout(() => {
				// 	obj[field] = event.key;
				// }, 10);
			}
			
			if(value.includes('.')){
				const [a,b] = value.split('.');
				if(a.toString().length > limitLenth){
					return event.preventDefault();
				}
				if(b.toString().length >= 2){
					return event.preventDefault();
				}
			}

			if(value && keyPressed == '-'){
				const [a,b] = value.split('-');
				if(a.toString().length > 0){
					return event.preventDefault();
				}
			}
			
			if (!value.includes('.')) {
				if(length >= limitLenth && keyPressed != '.'){
					return event.preventDefault();
				}
			}

			const dot = value.split('.').length - 1;
			
			if(dot > 0 && keyPressed == '.'){
				return event.preventDefault();
			}

			const minus = value.split('-').length - 1;
			if(minus > 0 && keyPressed == '-'){
				return event.preventDefault();
			}
		},
		// isValidNumber(event, limitLenth = null) {
		// 	const keysAllowed = [
		// 		'0',
		// 		'1',
		// 		'2',
		// 		'3',
		// 		'4',
		// 		'5',
		// 		'6',
		// 		'7',
		// 		'8',
		// 		'9',
		// 		'.',
		// 	];
		// 	const keyPressed = event.key;
		// 	if (!keysAllowed.includes(keyPressed)) {
		// 		event.preventDefault();
		// 	}
		// },
		isValidNumber(event, limitLength = null, limitDot = null) {
			const value = event.target.value;
			const length = value.toString().length;
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.','-'];
				const keyPressed = event.key;
				if (!keysAllowed.includes(keyPressed)) {
					event.preventDefault()
				}
				if(keyPressed != '.'){
					if(length == limitLength){
						event.preventDefault()
					}else if(length == limitLength && length >= limitDot){
						event.preventDefault()
					}
					const totalDot = limitLength + limitDot;
					if(length > totalDot){
						event.preventDefault();
					}
					
				}else if(keyPressed == '.' && length > limitLength){
					if(length > limitLength){
						if(length >= limitLength){
							event.preventDefault()
						}
					}
					
				}else if(keyPressed == '.' && length < limitLength){
					if(length < limitLength){
						event.preventDefault()
					}
				}
		},
		validateErrorFields(data, errors, rules) {
			var isValid = 0;
			var length = data.length;

			if (!length) {
				return isValid;
			}

			data.forEach((result, index) => {
				const inputlength =
					result.result_number == null
						? 0
						: typeof result.result_number == 'number'
						? result.result_number.toString().length
						: result.result_number.length;
				if (inputlength != result.len) {
					errors[
						index
					].message = `Number must be ${result.len} characters.`;
					isValid++;
				}
			});

			return isValid;
		},
		clearValidationArray(errors) {
			if (!errors.length) {
				return;
			}

			errors.forEach((result, index) => {
				errors[index].message = null;
			});
		},
		dateToday() {
			const currentDate = new Date();
			return moment(currentDate).format('YYYY-MM-DD');
		},
		formatDate(param) {
			if (!param) return null;
			return moment(param).format('YYYY-MM-DD');
		},
		formatSlashDate(param) {
			if (!param) return null;
			return moment(param).format('YYYY/MM/DD');
		},
		containInArray(arr, data){
			if(!arr.length) return false;
            return arr.includes(data);
        },
		currentModule(modules, routeName){
			if(!modules || !routeName) return {};
			var currentModule = null
			var index = null
			const menu = modules.filter((obj,i) => {
				if(routeName == obj.param.route){
					index = { index: i};
					currentModule = {
						...index,
						...obj
					}
				}
			});

			return (menu && currentModule) ? currentModule : null;
		},
		formatNumber(date, format = 'DD-MM-YYYY'){
			if(!date) return;
			return moment(date).format(format);
		}
	}
};

export default mixins;
