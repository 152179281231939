const showPopup = (state) => state.showPopup;
const series = (state) => state.series;
const shifts = (state) => state.shifts;
const agents = (state) => state.agents;
const papers = (state) => state.papers;
const currencies = (state) => state.currencies;
const totals_papers = (state) => state.totals_papers;
const datas = (state) => state.datas;
const columnAmount = (state) => state.columnAmount;
const dataRemoved = (state) => state.dataRemoved;
const isViewer = (state) => state.isViewer;
const haveData = (state) => state.haveData;

export default {
	showPopup,
	series,
	shifts,
	agents,
	papers,
	totals_papers,
	currencies,
	datas,
	columnAmount,
	dataRemoved,
	isViewer,
	haveData,
};
