const series = (state) => state.series;
const seriesSpin = (state) => state.seriesSpin;
const companies = (state) => state.companies;
const reports = (state) => state.reports;
const reportSpin = (state) => state.reportSpin;
const companyName = (state) => state.companyName;
const reportDate = (state) => state.reportDate;
const subTotal = (state) => state.subTotal;
const modalVisable = (state) => state.modalVisable;
const company = (state) => state.company;
const seriesId = (state) => state.seriesId;

export default {
	series,
	seriesSpin,
	companies,
	reports,
	reportSpin,
	companyName,
	reportDate,
	subTotal,
	modalVisable,
	company,
	seriesId
};
