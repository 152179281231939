<template>
	<a-layout-header>
		<a-row type="flex">
			<collapse-menu
				v-if="$router.name != 'admin.dashboard'"
			></collapse-menu>
			<menu-layout />
		</a-row>
	</a-layout-header>
</template>

<script>
	import MenuLayout from './menu_layout.vue';
	import CollapseMenu from '@/views/layouts/collapse_menu';

	export default {
		name: 'HeaderLayout',
		components: {
			MenuLayout,
			CollapseMenu,
		},
		data() {
			return {};
		},
		methods: {
		},
	};
</script>

<style></style>
