const state = {
    loading: false,
    company: {
        user_id: undefined,
        company_name: "",
    },
    companies: [],
    usersOwner: [],
    team: {},
    agentLeader: [],
    checked: [],
    btnSave: false,
    spin: false,
    visible: false,
    versions: [],
    loadingModal: false
};

export default state;
