const component = (state) => state.component;
const users = (state) => state.users;
const userSpin = (state) => state.userSpin;
const companies = (state) => state.companies;
const agentLeaders = (state) => state.agentLeaders;
const agentName = (state) => state.agentName;
const fromDate = (state) => state.fromDate;
const toDate = (state) => state.toDate;
const reports = (state) => state.reports;
const memberSpin = (state) => state.memberSpin;
const isTeam = (state) => state.isTeam;
const series = (state) => state.series;

export default {
	component,
	users,
	userSpin,
	companies,
	agentLeaders,
	agentName,
	fromDate,
	toDate,
	reports,
	memberSpin,
	isTeam,
	series
};
