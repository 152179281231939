import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetchAgentLeaders = ({ commit }, payload) => {
	commit('UPDATE_AGENT_LEADER_SPIN', true);
	const { query } = router.currentRoute;

	const url = getQueryUrl(query);

	return request({
		url: `${PRIVATE_API_PREFIX}agent-leader-reports/agent-leaders${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			if (response.status == 200) {
				const { data } = response;
				commit('UPDATE_AGENT_LEADER', data);
				commit('UPDATE_AGENT_LEADER_SPIN', false);
			}
		})
		.finally(() => {
			commit('UPDATE_AGENT_LEADER_SPIN', false);
		});
};

const fetchReports = ({ commit }, payload) => {
	commit('UPDATE_REPORT_SPIN', true);
	const { query } = router.currentRoute;

	const { id } = router.currentRoute.params;

	const url = getQueryUrl(query);
	if (id) {
		return request({
			url: `${PRIVATE_API_PREFIX}agent-leader-reports/${id}/agent-leaders${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				if (response.status == 200) {
					const { data } = response;
					// commit('UPDATE_REPORT', data);
					commit('UPDATE_TOTAL_REPORT', data)
					commit('UPDATE_REPORT_SPIN', false);
				} else {
					commit('UPDATE_TOTAL_REPORT', [])
				}
			})
			.finally(() => {
				commit('UPDATE_REPORT_SPIN', false);
			});
	} else {
		commit('UPDATE_REPORT_SPIN', false);
	}
};

const fetchCompany = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leader-reports/companies`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status == 200) {
			const { data } = response;
			commit('UPDATE_COMPANY', data);
		}
	});
};

const fetchSeries = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leader-reports/series`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status == 200) {
			const { data } = response;
			commit('UPDATE_SERIES', data);
		}
	});
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchAgentLeaders,
	fetchReports,
	fetchCompany,
	fetchSeries
};
