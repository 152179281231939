const state = {
	component: '',
	users: [],
	userSpin: false,
	companies: [],
	agentLeaders: [],
	agentName: null,
	fromDate: null,
	toDate: null,
	reports: [],
	memberSpin: false,
	isTeam: null,
	series: []
};

export default state;
