import Vue from 'vue';

const components = {
	'fs-icon': () => import('@/components/iconify.vue'),
	'user-row': () => import('@/components/user_row.vue'),
	'aut-profile': () => import('@/views/layouts/auth_profile.vue'),
	'vue-simple-scrollbar': () => import('vue-simple-scrollbar'),
	'v-spin': () => import('@/components/spining.vue'),
	'photo-viewer': () => import('@/components/photoviewer.vue'),
	'vue-custom-scrollbar': () => import('vue-custom-scrollbar'),

	'view_series_17': () => import('@/components/view_series/abcd_f_i_n/abcd_fin_evening_form.vue'),
	'view_series_18': () => import('@/components/view_series/abcd_f_i_n/abcd_fin_night_form.vue'),

	'view_series_21': () => import('@/components/view_series/khmer_lotto/kh_loto_early_morning.vue'),
	'view_series_22': () => import('@/components/view_series/khmer_lotto/kh_morning_form.vue'),
	'view_series_23': () => import('@/components/view_series/khmer_lotto/kh_noon_form.vue'),
	'view_series_24': () => import('@/components/view_series/khmer_lotto/kh_afternoon_form.vue'),
	'view_series_27': () => import('@/components/view_series/khmer_lotto/kh_evening_form.vue'),
	'view_series_28': () => import('@/components/view_series/khmer_lotto/kh_night_form.vue'),

	'view_series_32': () => import('@/components/view_series/lucky_dragon_lotto/lucky_dragon_lotto_morning.vue'),
	'view_series_34': () => import('@/components/view_series/lucky_dragon_lotto/lucky_dragon_lotto_afternoon.vue'),
	'view_series_37': () => import('@/components/view_series/lucky_dragon_lotto/lucky_dragon_lotto_evening.vue'),
	'view_series_38': () => import('@/components/view_series/lucky_dragon_lotto/lucky_dragon_lotto_night.vue'),

	'view_series_42': () => import('@/components/view_series/hoso/hoso_vn_morning_form.vue'),
	'view_series_43': () => import('@/components/view_series/hoso/hoso_vn_noon_form.vue'),
	'view_series_44': () => import('@/components/view_series/hoso/hoso_vn_afternoon_form.vue'),
	'view_series_47': () => import('@/components/view_series/hoso/hoso_vn_evening_form.vue'),
	'view_series_48': () => import('@/components/view_series/hoso/hoso_vn_night_form.vue'),

	'view_series_63': () => import('@/components/view_series/tinhnam/tinhnam_noon_form.vue'),
	'view_series_64': () => import('@/components/view_series/tinhnam/tinhnam_afternoon_form.vue'),
	'view_series_67': () => import('@/components/view_series/tinhnam/tinhnam_evening_form.vue'),
	'view_series_68': () => import('@/components/view_series/tinhnam/tinhnam_night_form.vue'),

	'view_series_82': () => import('@/components/view_series/lmhsb/lmhsb_morning.vue'),
	'view_series_83': () => import('@/components/view_series/lmhsb/lmhsb_noon.vue'),
	'view_series_84': () => import('@/components/view_series/lmhsb/lmhsb_afternoon.vue'),
	'view_series_85': () => import('@/components/view_series/lmhsb/lmhsb_afternoon_2.vue'),
	'view_series_86': () => import('@/components/view_series/lmhsb/lmhsb_afternoon_3.vue'),
	'view_series_87': () => import('@/components/view_series/lmhsb/lmhsb_evening.vue'),
	'view_series_88': () => import('@/components/view_series/lmhsb/lmhsb_night.vue'),

	'view_series_73': () => import('@/components/view_series/prng/prng_noon_form.vue'),
	'view_series_74': () => import('@/components/view_series/prng/prng_afternoon_form.vue'),
	'view_series_77': () => import('@/components/view_series/prng/prng_evening_form.vue'),
	'view_series_78': () => import('@/components/view_series/prng/prng_night_form.vue'),

	'view_series_54': () => import('@/components/view_series/xoso/xoso_evening_form.vue'),

	'view_series_97': () => import('@/components/view_series/minh_ngoc/minhngoc_evening_form.vue'),
	'view_series_98': () => import('@/components/view_series/minh_ngoc/minhngoc_night_form.vue'),

	'view-number-entry': () => import('@/components/view_numbers/number_list.vue'),
};

Object.entries(components).forEach(([name, component]) =>
	Vue.component(name, component)
);
