import request from '@/utils/request';
import router from '@/router'
import { PRIVATE_API_PREFIX, BASE_URL } from "@/utils/api_prefix";

const fetUsers = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	let url = '';
	if (query.page) {
		url = '?page=' + query.page;
	}

	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	})
		.then((result) => {
			if (result.status === 200) {
				commit('UPDATE_USER', result.data)
				commit('UPDATE_PAGINATION', result.meta)
			}
		});
};

const fetAgents = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}group-lines/agents`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_AGENTS', response.data);
		}
		return response;
	});
};
const fetSeries = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}group-lines/series`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_SERIES', response.data);
		}
		return response;
	})
};
const fetGroupLines = ({ commit }) => {

	let url = '';
	const query = router.currentRoute.query;
	if (query.param != undefined && query.param != '') url += `?param=${query.param}`
	if (query.series_name != undefined && query.series_name != '') url += `?series_name=${query.series_name}`
	return request({
		url: `${PRIVATE_API_PREFIX}group-lines${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_LINES', response.data);
		}
		return response;
	});
};
const createGroupLine = ({ dispatch, commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}group-lines`,
		method: 'post',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_LINES', response.data);
			dispatch('fetGroupLines')
			dispatch('fetGroupLineAmounts');
		}
		return response;
	})
};
const editGroupLine = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}group-lines/` + payload + "/edit",
		method: 'get',
		data: payload
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_EDIT_LINE', response.data);
			commit('UPDATE_LINE_ID', payload);
		}
	})
};
const updateGroupLine = ({ commit, dispatch }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}group-lines/` + payload.id,
		method: 'put',
		data: payload
	}).then((response) => {
		if (response.status === 200) {
			dispatch('fetGroupLines');
			dispatch('fetGroupLineAmounts');
		}
		return response;
	})
};
const fetGroupLineAmounts = ({ commit }, payload) => {
	let url = '';
	const query = router.currentRoute.query;
	if (query.param != undefined && query.param) url += `?param=${query.param}`
	return request({
		url: `${PRIVATE_API_PREFIX}group-lines/amounts${url}`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_AMOUNTS', response.data);
			commit('UPDATE_SERIES_ID', payload);
		}
	});
};


export default {
	fetUsers,
	fetAgents,
	fetSeries,
	createGroupLine,
	fetGroupLines,
	editGroupLine,
	updateGroupLine,
	fetGroupLineAmounts
};
