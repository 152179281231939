const showForm = (state) => state.showForm;
const getAgentLeaders = (state) => state.showAgentLeaders;
const getAllSeries = (state) => state.showAllSeries;
const getAllCompanies = (state) => state.showAllCompanies;
const getBookPayments = (state) => state.showBookPayments;
const loading = (state) => state.loading;

export default {
	showForm,
	getAgentLeaders,
	getAllSeries,
	getAllCompanies,
	getBookPayments,
	loading
};
