const UPDATE_LOADING = (state, payload) => {
	state.loading = payload;
};

const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
}

const UPDATE_SERYID = (state, payload) => {
	state.seryId = payload;
}
const UPDATE_USERS = (state, payload) => {
	state.users = payload;
}
const UPDATE_SPINNING = (state, payload) => {
	state.spinning = payload;
}
const UPDATE_SPIN = (state, payload) => {
	state.spin = payload;
}
const UPDATE_AGENT_BY_SERIES = (state, payload) => {
	state.agents = payload;
}
const UPDATE_LOTTERY_BY_SERIES = (state, payload) => {
	state.lottery = payload;
}
const UPDATE_LOTTERY_TOTAL = (state, payload) => {
	state.subTotal = payload;
}

const UPDATE_SERIES_NAME = (state, payload) => {
	state.seriesName = payload;
}

const UPDATE_SERIES_ITEM = (state, payload) => {
	state.seriesItems = payload;
}

export default {
	UPDATE_LOADING,
	UPDATE_SERIES,
	UPDATE_SERYID,
	UPDATE_USERS,
	UPDATE_SPINNING,
	UPDATE_AGENT_BY_SERIES,
	UPDATE_LOTTERY_BY_SERIES,
	UPDATE_LOTTERY_TOTAL,
	UPDATE_SPIN,
	UPDATE_SERIES_NAME,
	UPDATE_SERIES_ITEM
};
