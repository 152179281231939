const loading = (state) => state.loading;

const getUsers = (state) => {
    return state.users;
};

const team = (state) => {
    return state.team;
};

const countGroupWork = (state) => {
   return state.groupWorks.length;
};

const getGroup = (state) => {
    return state.groupWorks;
};

export default {
    loading,
    getUsers,
    team,
    countGroupWork,
    getGroup,
};
