import request from '@/utils/request';
import {PRIVATE_API_PREFIX} from "@/utils/api_prefix";
import router from '@/router';

const fetchAgents = ({ commit, dispatch }, payload) => {
	const params = router.currentRoute;
	const url = getQueryUrl(params.query);
	return  request({
		url: `${PRIVATE_API_PREFIX}upload-papers/agents${url}`,
		method: 'get',
		data: {},
	})
	.then((response) => {
		if(response.status === 200){
			commit("UPDATE_AGENT", response.data);
		}
		return response;
	});
};

const fetchSeries = ({ commit, dispatch }, payload) => {
	return request({
		url : `${PRIVATE_API_PREFIX}upload-papers/series`,
		method : 'get',
		data: {},
	}).then((response) => {
		if(response.status === 200){
			commit('UPDATE_SERIES', response.data);
		}
		return response;
	})
}

const fetchSeriesShifts = ({commit, dispatch}, payload)=>{
	if(payload == undefined) payload =0
	return request({
		url:`${PRIVATE_API_PREFIX}upload-papers/${payload}/shifts`,
		method : 'get',
		data: {},
	}).then((response)=>{
		if(response.status === 200){
			commit('UPDATE_SERIES_SHIFTS', response.data);
		}
		return response;
	})
}

const uploadPaperLottery = ({dispatch}, payload)=> {
	let formData = new FormData();
	var img = payload.file_name.length;
	var length = 0;
	formData.append("paper_date", payload.paper_date === null ? null : payload.paper_date);
	formData.append("user_id", payload.user_id === undefined ? '' : payload.user_id);
	formData.append("series_id", payload.series_id === undefined ? '' : payload.series_id);
	formData.append("lottery_time_type_id", payload.lottery_time_type_id === undefined ? '' : payload.lottery_time_type_id);
	if(payload.file_name.length) {
		payload.file_name.forEach((file,i)=>{
			length++;
			formData.append("file_name[]", file[0]);
		})
	}

	if(length == img){
		return request({
			url:`${PRIVATE_API_PREFIX}upload-papers`,
			method : 'post',
			data: formData,
		}).then((response)=>{
			if(response.status == 200){
				dispatch('fetchAgents')
			}
			return response;
		})
	}
	
}

function getQueryUrl(queries){
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key,index) => {
		if(queries[key] != undefined){
			i+=1;
			if(i == 1){
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		} 
	})
	return url;
}
export default {
	fetchAgents,
	fetchSeries,
	fetchSeriesShifts,
	uploadPaperLottery
};
