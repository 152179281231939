import axios from 'axios';
import Vue from 'vue';
import notification from 'ant-design-vue/es/notification';
import { VueAxios } from './axios';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const authReq = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
});

const errorHandler = (error) => {
	if (error.response.status === 500) {
		notification.error({
			message: 'Internal server errors',
			description: '',
		});
	}
	return Promise.reject(error);
};

authReq.interceptors.request.use((config) => {
	const token = Vue.$cookies.get('Access-token');
	if (token) {
		config.headers['Authorization'] = `Bearer ${token}`;
	}
	// config.headers.common['Accept-Language'] = Vue.$cookies.get('locale');
	return config;
}, errorHandler);

authReq.interceptors.response.use((response) => {
	return response.data;
}, errorHandler);

const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, authReq);
	},
};

export default authReq;

export { installer as VueAxios, authReq as axios };
