import request from '@/utils/request';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';
import router from '@/router';

const fetchTotalAgent = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-total-agents`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		if (reponse.status === 200) {
			commit('UPDATE_TOTAL_AGENT', reponse.data);
		}
		return reponse;
	});
};

const fetchTotalPaperToday = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-total-paper-todays`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		if (reponse.status === 200) {
			commit('UPDATE_TOTAL_PAPER_TODAY', reponse.data);
		}
		return reponse;
	});
};

const fetchTotalPaper = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-total-papers`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		if (reponse.status === 200) {
			commit('UPDATE_TOTAL_PAPER', reponse.data);
		}
		return reponse;
	});
};

const fetchTotalagentNoData = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-total-agent-nodata-todays`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		if (reponse.status === 200) {
			commit('UPDATE_TOTAL_AGENT_NODATA', reponse.data);
		}
		return reponse;
	});
};

const fetchOrderBookPayments = ({ commit }, payload) => {
	commit('UPDATE_ORDER_BOOK_PAYMENT_SPIN', true);
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-order-agent-bookpayments`,
		method: 'get',
		data: {},
	})
		.then((reponse) => {
			if (reponse.status === 200) {
				commit('UPDATE_ORDER_BOOK_PAYMENT', reponse.data);
			}
			return reponse;
		})
		.finally(() => {
			commit('UPDATE_ORDER_BOOK_PAYMENT_SPIN', false);
		});
};

const fetchOrderPlayer = ({ commit }, payload = null) => {
	commit('UPDATE_ORDER_PLAYER_SPIN', true);
	// const route = router.currentRoute;
	// const url = getQueryUrl(route.query);
	const url = payload ? `?month=${payload}` : ``;
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-order-players${url}`,
		method: 'get',
		data: {},
	})
		.then((reponse) => {
			if (reponse.status === 200) {
				commit('UPDATE_ORDER_PLAYER', reponse.data);
			}
			return reponse;
		})
		.finally(() => {
			commit('UPDATE_ORDER_PLAYER_SPIN', false);
		});
};

const fetchUserStatistic = ({ commit }, payload) => {
	const url = payload ? `?month=${payload}` : ``;
	commit('UPDATE_ORDER_USER_STATISTIC_SPIN', true);
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-user-statistics${url}`,
		method: 'get',
		data: {},
	})
		.then((reponse) => {
			if (reponse.status === 200) {
				commit('UPDATE_ORDER_USER_STATISTIC', reponse.data);
			}
			return reponse;
		})
		.finally(() => {
			commit('UPDATE_ORDER_USER_STATISTIC_SPIN', false);
		});
};

const fetchCompanyRevenue = ({ commit }, payload) => {
	commit('UPDATE_COMPANY_REVENUE_SPIN', true);
	return request({
		url: `${PRIVATE_API_PREFIX}dashboards/get-company-revenues`,
		method: 'get',
		data: {},
	})
		.then((reponse) => {
			if (reponse.status === 200) {
				commit('UPDATE_COMPANY_REVENUE', reponse.data.company_revenues);
				commit(
					'UPDATE_TOTAL_COMPANY_REVENUE',
					reponse.data.total_revenue
				);
			}
			return reponse;
		})
		.finally(() => {
			commit('UPDATE_COMPANY_REVENUE_SPIN', false);
		});
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchTotalAgent,
	fetchTotalPaperToday,
	fetchTotalPaper,
	fetchTotalagentNoData,
	fetchOrderBookPayments,
	fetchOrderPlayer,
	fetchUserStatistic,
	fetchCompanyRevenue,
};
