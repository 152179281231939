const state = {
	auth: {},
    isCollapse: true,
	openKey: [],
	spin: true,
	menus: [],
	allMenus: [],
	pattern: "/^-?d+.?d*$/",
	currentRouteName: null,
	isViewer: false,
	disableMenu: false,
	allowProgressRoutes: [
		'admin.company.report',
		'admin.agent.leader.report',
		'admin.daily.agent.result',
		'admin.daily.company.result',
		'admin.daily.company.result',
		'admin.job.run'
	],
	progressReport: null
};

export default state;
