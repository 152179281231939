const state = {
	users: [],
	showModal: false,
    drawerVisable: false,
    genders: [],
    roles: [],
    userTypes: [],
    statuses: [],
    groupWorks: [],
    commissions: [],
    prizes: [],
    temId: 0,
    user: {
        id: '',
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        phone: '',
        user_type_id: undefined,
        gender_id: undefined,
        status: undefined,
        password: '',
        role: undefined,
        company: [],
        start_time: null,
        end_time: null
    },
    resetUser: {
        id: '',
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        phone: '',
        user_type_id: undefined,
        gender_id: 1,
        status: 1,
        password: '',
        role: undefined,
        company: [],
        start_time: null,
        end_time: null
    },
    agentLeaders:[],
    selectedUserSettings: [],
    userRoleType: null,
    userSpinning: false,
    companies: [],
    eliUsers: [],
    eligibleSpin: false,
    totalUser: 0,
    commissionVisible: false,
    prizeVisible: false,
    commissionVersions: [],
    prizeVersions:[],
    userId: 0,
    versionLoading: false,
    employees: [],
    agents: [],
    agentLeading: false,
    plainOptions:[],
    agentGraphData: [],
    agentGraphNotes: []
};

export default state;
