const state = {
	series: [],
	seriesSpin: false,
	companies: [],
	component: '',
	reports: [],
	reportSpin: false,
	companyName: null,
	reportDate: null,
	subTotal: {},
	modalVisable: false,
	// company: {
	// 	id_kh: null,
	// 	id_dollar: null,
	// 	id_bath: null,
	// 	id_dong: null,
	// 	currency_id_kh: null,
	// 	currency_id_dollar: null,
	// 	currency_id_bath: null,
	// 	currency_id_dong: null,
	// 	income_balance_kh: null,
	// 	income_balance_dollar: null,
	// 	income_balance_bath: null,
	// 	income_balance_dong: null,
	// 	expense_balance_kh: null,
	// 	expense_balance_dollar: null,
	// 	expense_balance_bath: null,
	// 	expense_balance_dong: null,
	// 	balance_in_balance_out_kh: null,
	// 	balance_in_balance_out_dollar: null,
	// 	balance_in_balance_out_bath: null,
	// 	balance_in_balance_out_dong: null
	// },
	company: {
		currency_id_kh: null,
		currency_id_dollar: null,
		currency_id_bath: null,
		currency_id_dong: null,
		income_balance_kh: null,
		income_balance_dollar: null,
		income_balance_bath: null,
		income_balance_dong: null,
		expense_balance_kh: null,
		expense_balance_dollar: null,
		expense_balance_bath: null,
		expense_balance_dong: null
	},
	seriesId: null
};

export default state;
