import request from '@/utils/request';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetchUsers = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		return reponse;
	});
};

const fetchSeries = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}job-runs/series`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		const { status, data } = reponse;
		if (status == 200) {
			commit('UPDATE_SERIES', data);
		}

		return reponse;
	});
};

const fetchCompany = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}job-runs/companies`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		const { status, data } = reponse;
		if (status == 200) {
			commit('UPDATE_COMPANIES', data);
		}

		return reponse;
	});
};

const fetchAgentLeader = ({ commit }, payload) => {
	if(payload){
		return request({
			url: `${PRIVATE_API_PREFIX}job-runs/get-agent-leaders/${payload}`,
			method: 'get',
			data: {},
		}).then((reponse) => {
			const { status, data } = reponse;
			if (status == 200) {
				commit('UPDATE_AGENT_LEADER', data);
			}
	
			return reponse;
		});
	} else commit('UPDATE_AGENT_LEADER', []);
};

const fetchAgents = ({ commit }, payload) => {
	if(payload){
		return request({
			url: `${PRIVATE_API_PREFIX}job-runs/get-agents/${payload}`,
			method: 'get',
			data: {},
		}).then((reponse) => {
			const { status, data } = reponse;
			if (status == 200) {
				commit('UPDATE_AGENT', data);
			}
	
			return reponse;
		});
	} else commit('UPDATE_AGENT', []);
};

const runReport = ({ commit }, payload) => {
	if(payload){
		return request({
			url: `${PRIVATE_API_PREFIX}job-runs`,
			method: 'post',
			data: {
				payload: JSON.stringify(payload)
			},
		}).then((reponse) => {
			console.log(reponse)
			return reponse;
		});
	} 
};

export default {
	fetchUsers,
	fetchSeries,
	fetchCompany,
	fetchAgentLeader,
	fetchAgents,
	runReport
};
