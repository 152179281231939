import axios from 'axios';
import Vue from 'vue';
import notification from 'ant-design-vue/es/notification';
import { VueAxios } from './axios';
import router from '@/router';
import VueJwtDecode from 'vue-jwt-decode';
import moment from 'moment-timezone';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const PREFIX_URL = process.env.VUE_APP_API_PRIVATE_PREFIX;

const request = axios.create({
	baseURL: API_BASE_URL,
	timeout: 30000,
});

var isUnauthorized = 0;
var isInternalServer = 0;

const errorHandler = (error) => {
	if (error.response.status === 401) {
		var timeout;
		isUnauthorized++;
		if (isUnauthorized == 1) {
			notification.error({
				message: 'Unauthorized',
				description: '',
				duration: 1,
			});
		}

		if (isUnauthorized) {
			timeout = setTimeout(() => {
				isUnauthorized = 0;
			}, 500);
		}

		if (!isUnauthorized) clearTimeout(timeout);

		let cookies = Vue.$cookies.keys();
		if (cookies) {
			cookies.forEach((cookie) => {
				if (cookie != 'locale') Vue.$cookies.set(cookie, '');
			});
		}

		router
			.push({
				name: 'admin.login',
			})
			.catch(() => {});
	} else if (error.response.status === 500) {
		isInternalServer++;

		if (isInternalServer == 1) {
			notification.error({
				message: 'Internal server errors',
				description: '',
				duration: 1,
			});
		}

		var serverTimeout;
		if (isInternalServer) {
			serverTimeout = setTimeout(() => {
				isInternalServer = 0;
			}, 500);
		}
		if (!isInternalServer) clearTimeout(serverTimeout);
	}
	return Promise.reject(error);
};

request.interceptors.request.use((config) => {
	const token = Vue.$cookies.get('Access-token');

	if (token) {
		config.headers['Authorization'] = `Bearer ${token}`;
	}
	// config.headers['Accept-Language'] = Vue.$cookies.get('locale');

	return config;
}, errorHandler);

request.interceptors.response.use((response) => {
	const jwtToken = VueJwtDecode.decode(Vue.$cookies.get('Access-token'));
	const exp = new Date(jwtToken.exp) * 1000;
	const currentTime = moment();

	const durationInMinutes = moment.duration(
		moment(exp).diff(moment(currentTime))
	);
	// 600 = 10mn * 60s
	if (durationInMinutes.asSeconds() < 600) {
		axios
			.get(API_BASE_URL + PREFIX_URL + 'auth/refresh-token', {
				headers: {
					Authorization: `Bearer ${Vue.$cookies.get('Access-token')}`,
				},
			})
			.then((res) => {
				if (res.data) {
					Vue.$cookies.set('Access-token', res.data);
				}
			});
	}

	return response.data;
}, errorHandler);

const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request);
	},
};

export default request;

export { installer as VueAxios, request as axios };
