import request from '@/utils/request';
import router from '@/router'
import {PRIVATE_API_PREFIX} from "@/utils/api_prefix";

const fetUsers = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	let url = '';
	if(query.page){
		url = '?page=' + query.page;
	}

	return  request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	})
	.then((result) => {
		if (result.status === 200) {
			commit('UPDATE_USER', result.data)
			commit('UPDATE_PAGINATION',result.meta)
		}
	});
};

export default {
	fetUsers
};
