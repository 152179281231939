import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import { languages } from '@/i18n/index.js';
import { defaultLocale } from '@/i18n/index.js';
import mixins from '@/mixins';
import ResizableBox from 'vue-resizable-box';
import 'ant-design-vue/dist/antd.less';
import '@/main.css';
import 'vue-simple-scrollbar/dist/vue-simple-scrollbar.css';
import 'vue-custom-scrollbar/dist/vueScrollbar.css';
import '@/components';
import VueCarousel from 'vue-carousel';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer';
import moment from 'moment-timezone';

moment.tz.setDefault('Asia/Phnom_Penh');

const messages = Object.assign(languages);

Vue.use(ResizableBox);
Vue.use(VueI18n);
Vue.use(Antd);
Vue.use(VueCookies);
Vue.mixin(mixins);
Vue.use(VueCarousel);
Vue.use(Viewer)

Vue.$cookies.config('7d');
Vue.config.productionTip = false;
const lang = Vue.$cookies.get('locale');

var i18n = new VueI18n({
	locale: lang ? lang : defaultLocale,
	fallbackLocale: 'en',
	messages,
});

const vueJs = new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App)
});

router.onReady(() => {
	vueJs.$mount('#app');
});

