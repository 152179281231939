const component = (state) => state.component;
const agentLeaders = (state) => state.agentLeaders;
const agentLeaderSpin = (state) => state.agentLeaderSpin;
const agentLeaderName = (state) => state.agentLeaderName;
const startDate = (state) => state.startDate;
const endDate = (state) => state.endDate;
const reports = (state) => state.reports;
const reportSpin = (state) => state.reportSpin;
const companies = (state) => state.companies;
const companyName = (state) => state.companyName;
const totalReports = (state) => state.totalReports;
const series = (state) => state.series;

export default {
	component,
	agentLeaders,
	agentLeaderSpin,
	agentLeaderName,
	startDate,
	endDate,
	reports,
	reportSpin,
	companies,
	companyName,
	totalReports,
	series
};
