
const loading = (state) => state.loading;
const series = (state) => state.series;
const agents = (state) => state.agents;
const papers = (state) => state.papers;
const checkedId = (state) => state.checkedId;
const PapersTotals = (state) => state.totalPapers;

export default {
	loading,
	series,
	agents,
	papers,
	checkedId,
	PapersTotals
};
