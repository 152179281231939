const state = {
	totalAgent: 0,
	totalPaperToday: 0,
	totalPaper: {
		this_month: 0,
		last_month: 0,
	},
	totalAgentNoData: 0,
	orderBooKPayments: [],
	orderBooKPaymentSpin: false,
	orderPlayers: [],
	orderPlayerSpin: false,
	userStatistic: {
		lablel: [],
		data: [],
	},
	userStatisticSpin: false,
	companyRevenueSpin: false,
	companyRevenues: [],
	totalCompanyRevenue: {},
};

export default state;
