const showForm = (state) => state.showForm;
const users = (state) => state.users;
const agent = (state) => state.agent;
const agents = (state) => state.agents;
const lotteries = (state) => state.lotteries;
const id = (state) => state.id;
const agent_id = (state) => state.agent_id;
const leaders = (state) => state.leaders;
const sery_id = (state) => state.sery_id;
const user = (state) => state.user;
const lottery = (state) => state.lottery;
// const checked = (state) => state.checked;
const approvedIds = (state) => state.approvedIds;
const totalUsers = (state) => state.totalUsers;
const companies = (state) => state.companies;
const spinning = (state) => state.spinning;
const spin = (state) => state.spin;
const agentId = (state) => state.agentId;
const visible = (state) => state.visible;
const versions = (state) => state.versions;
const modalLoading = (state) => state.modalLoading;

export default {
	showForm,
	users,
	agent,
	agents,
	lotteries,
	id,
	agent_id,
	leaders,
	sery_id,
	user,
	lottery,
	totalUsers,
	companies,
	spinning,
	spin,
	agentId,
	visible,
	versions,
	modalLoading,
	approvedIds
};
