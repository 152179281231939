<template>
	<div
		v-click-outside="handleClickOut"
		class="menu-top-icon relative flex items-center"
	>
		<div class="absolute menu-home hidden"></div>
		<div
			@click="handleCollapseMenu()"
			class="click-outside-overlay absolute"
		></div>
		<a-col
			flex="95px"
			class="menu-colapse-icon relative flex justify-center items-center"
		>
			<a-icon
				:type="toggleCollapseMenu ? 'menu-unfold' : 'menu-fold'"
				:class="[!toggleCollapseMenu ? 'menu-fold-active' : '']"
			/>
			<div
				class="absolute text-white bg-white collapse-border-bottom"
			></div>
		</a-col>
	</div>
</template>
<script>
	import clickOutside from '@/directives/click_outside';
	import { mapGetters, mapMutations } from 'vuex';
	export default {
		name: 'CollaseMenu',
		directives: {
			'click-outside': clickOutside,
		},
		methods: {
			...mapMutations(['UPDATE_COLLAPSE_MENU']),
			handleCollapseMenu() {
				this.UPDATE_COLLAPSE_MENU(!this.toggleCollapseMenu);
			},
			handleClickOut() {
				if (!this.toggleCollapseMenu)
					this.UPDATE_COLLAPSE_MENU(!this.toggleCollapseMenu);
			},
		},
		computed: {
			...mapGetters(['toggleCollapseMenu', 'openKey']),
		},
	};
</script>
<style>
	.click-outside-overlay {
		width: 95px;
		height: 75px;
		top: 0;
		background: transparent;
		z-index: 1999;
	}
</style>

