const loading = (state) => state.loading;
const getTeam = (state) => state.team;
const getCompanyCount = (state) => state.companies.length;
const loadingModal = (state) => state.loadingModal;

export default {
    loading,
    getTeam,
    getCompanyCount,
    loadingModal
};
