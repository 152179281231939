const showForm = (state) => state.showForm;
const agents = (state) => state.agents;
const series = (state) => state.series;
const lines = (state) => state.lines;
const id = (state) => state.id;
const line = (state) => state.line;
const amounts = (state) => state.amounts;
const line_id = (state) => state.line_id;
const seriesName = (state) => state.seriesName;


export default {
	showForm,
	agents,
	series,
	lines,
	id,
	line,
	amounts,
	line_id,
	seriesName
};
