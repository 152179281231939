const UPDATE_POPUP = (state, payload) => {
	state.showPopup = payload;
};

const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
};

const UPDATE_SHIFTS = (state, payload) => {
	state.shifts = payload;
};

const UPDATE_AGENTS = (state, payload) => {
	state.agents = payload;
};

const UPDATE_PAPERS = (state, payload) => {
	state.papers = payload;
};

const UPDATE_TOTAL_PAPERS = (state, payload) => {
	state.totals_papers = payload;
};

const UPDATE_ACTIVE_PAPERS = (state, payload) => {
	state.activePaper = payload;
};

const UPDATE_CURRENCIES = (state, payload) => {
	state.currencies = payload;
};

const UPDATE_DATAS = (state, payload) => {
	state.datas = payload;
};

const UPDATE_COLUMN_AMOUNT = (state, payload) => {
	state.columnAmount = payload;
};

const UPDATE_COLUMNS = (state, payload) => {
	state.columns = [payload];
};

const UPDATE_DEFAULT_COLUMN = (state, payload) => {
	state.defaultValue = payload;
};

const UPDATE_ADD_TABLE = (state, payload) => {
	state.columns.push(payload);
};

const UPDATE_REMOVE_TABLE = (state, payload) => {
	state.dataRemoved = payload;
}

const UPDATE_IS_VIEWER = (state, payload) => {
	state.isViewer = payload;
}

const UPDATE_HAVE_DATA = (state, payload) => {
	state.haveData = payload;
}

const UPDATE_NUMBER_SPIN = (state, payload) => {
	state.numberSpin = payload;
}

const UPDATE_NUMBER_LIST = (state, payload) => {
	state.numbers = payload;
}

const UPDATE_READY = (state, payload) => {
	state.ready = payload;
}

export default {
	UPDATE_POPUP,
	UPDATE_SERIES,
	UPDATE_SHIFTS,
	UPDATE_AGENTS,
	UPDATE_PAPERS,
	UPDATE_TOTAL_PAPERS,
	UPDATE_ACTIVE_PAPERS,
	UPDATE_CURRENCIES,
	UPDATE_DATAS,
	UPDATE_COLUMN_AMOUNT,
	UPDATE_COLUMNS,
	UPDATE_DEFAULT_COLUMN,
	UPDATE_ADD_TABLE,
	UPDATE_REMOVE_TABLE,
	UPDATE_IS_VIEWER,
	UPDATE_HAVE_DATA,
	UPDATE_NUMBER_SPIN,
	UPDATE_NUMBER_LIST,
	UPDATE_READY
};
