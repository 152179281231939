import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetchSeries = ({ commit }, payload) => {
	commit('UPDATE_SERIES_SPIN', true);
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	// if(query.company){
	return request({
		url: `${PRIVATE_API_PREFIX}company-final-reports/series${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			const { status, data } = response;
			if (status === 200) {
				commit('UPDATE_SUB_TOTAL', data.sub_total);
				commit('UPDATE_SERIES', data.series);
				commit('UPDATE_SERIES_SPIN', false);
			}
		})
		.finally(() => {
			commit('UPDATE_SERIES_SPIN', false);
		});
	// } else {
	// 	commit('UPDATE_SERIES_SPIN', false);
	// }
};

const fetchCompanies = ({ commit }, payload) => {
	commit('UPDATE_COMPANY_SPIN', true);
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	return request({
		url: `${PRIVATE_API_PREFIX}company-final-reports/companies${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			if (response.status === 200) {
				const { data } = response;
				commit('UPDATE_COMPANIES', data);
			}
			return response;
		})
		.finally(() => {
			commit('UPDATE_COMPANY_SPIN', false);
		});
};

const fetchIncomeExpense = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	return request({
		url: `${PRIVATE_API_PREFIX}company-final-reports/${payload}/income-expenses${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		const { status, data } = response;
		if (status == 200 && data) {
			commit('UPDATE_INCOME_EXPENSE', data[0]);
		}
		return response;
	});
};

const storeIncomeExpense = ({ dispatch }, payload) => {
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	const { id } = router.currentRoute.params;

	return request({
		url: `${PRIVATE_API_PREFIX}company-final-reports/${id}/income-expenses${url}`,
		method: 'post',
		data: payload,
	}).then((response) => {
		const { status } = response;
		if (status == 200) {
			dispatch('fetchCompanies');
		}
		return response;
	});
};

const fetchReports = ({ commit }, payload) => {
	commit('UPDATE_REPORT_SPIN', true);
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	const { id } = router.currentRoute.params;
	if (id) {
		return request({
			url: `${PRIVATE_API_PREFIX}company-final-reports/${id}/lists${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				if (response.status === 200) {
					const { data } = response;
					commit('UPDATE_REPORT_SPIN', false);
					commit('UPDATE_REPORT', data);
				}
			})
			.finally(() => {
				commit('UPDATE_REPORT_SPIN', false);
			});
	} else {
		commit('UPDATE_REPORT_SPIN', false);
	}
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchSeries,
	fetchCompanies,
	fetchReports,
	fetchIncomeExpense,
	storeIncomeExpense,
};
