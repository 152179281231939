const state = {
	showForm: false,
	isSpining: false,
	showAgentLeaders: {},
	showAllSeries: [],
	showAllCompanies: [],
	showBookPayments: [
		{
			id: '',
			currency_id: '',
			agent_leader_id: '',
			series_id: '',
			company_id: '',
			dates: '',
			balance_in: '',
			balance_out: '',
			creditnote: '',
			balance_in_symbol: 'សងរៀល',
			currency_symbol: '៛',
			balance_out_symbol: 'ខ្ចីរៀល',
			creditnote_symbol: 'តវ៉ារៀល',
		},
		{
			id: '',
			currency_id: '',
			agent_leader_id: '',
			series_id: '',
			company_id: '',
			dates: '',
			balance_in: '',
			balance_out: '',
			creditnote: '',
			balance_in_symbol: 'សងដុល្លា',
			currency_symbol: '$',
			balance_out_symbol: 'ខ្ចីដុល្លា',
			creditnote_symbol: 'តវ៉ាដុល្លា',
		},
		{
			id: '',
			currency_id: '',
			agent_leader_id: '',
			series_id: '',
			company_id: '',
			dates: '',
			balance_in: '',
			balance_out: '',
			creditnote: '',
			balance_in_symbol: 'សងបាត',
			currency_symbol: '฿',
			balance_out_symbol: 'ខ្ចីបាត',
			creditnote_symbol: 'តវ៉ាបាត',
		},
		{
			id: '',
			currency_id: '',
			agent_leader_id: '',
			series_id: '',
			company_id: '',
			dates: '',
			balance_in: '',
			balance_out: '',
			creditnote: '',
			balance_in_symbol: 'សងដុង',
			currency_symbol: '₫',
			balance_out_symbol: 'ខ្ចីដុង',
			creditnote_symbol: 'តវ៉ាដុង',
		},
	],
	loading: false,
	allSeries: []
};

export default state;
