const loading = (state) => state.loading;
const series = (state) => state.series;
const users = (state) => state.users;
const spinning = (state) => state.spinning;
const agents = (state) => state.agents;
const lottery = (state) => state.lottery;
const subTotal = (state) => state.subTotal;
const spin = (state) => state.spin;
const seriesName = (state) => state.seriesName;
const seriesItems = (state) => state.seriesItems;

export default {
	loading,
	series,
	users,
	spinning,
	agents,
	lottery,
	subTotal,
	spin,
	seriesName,
	seriesItems
};
