const totalAgent = (state) => state.totalAgent;
const totalPaperToday = (state) => state.totalPaperToday;
const totalPaper = (state) => state.totalPaper;
const totalAgentNoData = (state) =>
	state.totalAgentNoData > 0 ? state.totalAgentNoData : 0;
const orderBooKPayments = (state) => state.orderBooKPayments;
const orderBooKPaymentSpin = (state) => state.orderBooKPaymentSpin;
const orderPlayers = (state) => state.orderPlayers;
const orderPlayerSpin = (state) => state.orderPlayerSpin;
const userStatistic = (state) => state.userStatistic;
const userStatisticSpin = (state) => state.userStatisticSpin;
const companyRevenues = (state) => state.companyRevenues;
const totalCompanyRevenue = (state) => state.totalCompanyRevenue;
const companyRevenueSpin = (state) => state.companyRevenueSpin;

export default {
	totalAgent,
	totalPaperToday,
	totalPaper,
	totalAgentNoData,
	orderBooKPayments,
	orderBooKPaymentSpin,
	orderPlayers,
	orderPlayerSpin,
	userStatistic,
	userStatisticSpin,
	companyRevenues,
	totalCompanyRevenue,
	companyRevenueSpin,
};
