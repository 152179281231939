const state = {
	showPopup: false,
	series: [],
	shifts: [],
	agents: [],
	papers: [],
	currencies: [],
	totals_papers: 0,
	activePaper: 0,
	haveData: false,
	datas:[],
	columnAmount:[],
	columns: [],
	defaultValue: [],
	dataRemoved: [],
	isViewer: false,
	numberSpin: false,
	numbers:[],
	ready: false, //await data before another can be save
	// totalMoney:{},
};

export default state;
