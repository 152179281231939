const UPDATE_TOTAL_AGENT = (state, payload) => {
	state.totalAgent = payload;
};

const UPDATE_TOTAL_PAPER_TODAY = (state, payload) => {
	state.totalPaperToday = payload;
};

const UPDATE_TOTAL_PAPER = (state, payload) => {
	state.totalPaper = payload;
};

const UPDATE_TOTAL_AGENT_NODATA = (state, payload) => {
	state.totalAgentNoData = payload;
};

const UPDATE_ORDER_BOOK_PAYMENT = (state, payload) => {
	state.orderBooKPayments = payload;
};
const UPDATE_ORDER_BOOK_PAYMENT_SPIN = (state, payload) => {
	state.orderBooKPaymentSpin = payload;
};

const UPDATE_ORDER_PLAYER = (state, payload) => {
	state.orderPlayers = payload;
};

const UPDATE_ORDER_PLAYER_SPIN = (state, payload) => {
	state.orderPlayerSpin = payload;
};

const UPDATE_ORDER_USER_STATISTIC = (state, payload) => {
	state.userStatistic = payload;
};

const UPDATE_ORDER_USER_STATISTIC_SPIN = (state, payload) => {
	state.userStatisticSpin = payload;
};

const UPDATE_COMPANY_REVENUE = (state, payload) => {
	state.companyRevenues = payload;
};

const UPDATE_TOTAL_COMPANY_REVENUE = (state, payload) => {
	state.totalCompanyRevenue = payload;
};

const UPDATE_COMPANY_REVENUE_SPIN = (state, payload) => {
	state.companyRevenueSpin = payload;
};

export default {
	UPDATE_TOTAL_AGENT,
	UPDATE_TOTAL_PAPER_TODAY,
	UPDATE_TOTAL_PAPER,
	UPDATE_TOTAL_AGENT_NODATA,
	UPDATE_ORDER_BOOK_PAYMENT,
	UPDATE_ORDER_BOOK_PAYMENT_SPIN,
	UPDATE_ORDER_PLAYER,
	UPDATE_ORDER_PLAYER_SPIN,
	UPDATE_ORDER_USER_STATISTIC,
	UPDATE_ORDER_USER_STATISTIC_SPIN,
	UPDATE_COMPANY_REVENUE,
	UPDATE_TOTAL_COMPANY_REVENUE,
	UPDATE_COMPANY_REVENUE_SPIN,
};
