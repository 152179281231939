import request from '@/utils/request';
import router from '@/router'
import {PRIVATE_API_PREFIX} from "@/utils/api_prefix";

const fetUsers = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	let url = '';
	if(query.page){
		url = '?page=' + query.page;
	}

	return  request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	})
	.then((result) => {
		console.log(result)
	});
};

const fetchEmployee = ({ commit }) =>{
	const route = router.currentRoute;
    const url = getQueryUrl(route.query);
    commit("UPDATE_SPIN_USER", true);
    return request({
        url: `${PRIVATE_API_PREFIX}salaries/employees${url}`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_EMPLOYEES", response.data);
        return response;
    }).finally(()=>{
        commit("UPDATE_SPIN_USER", false);
    })
}
const fetchCompany = ({ commit }) =>{
	const route = router.currentRoute;
    const url = getQueryUrl(route.query);
    return request({
        url: `${PRIVATE_API_PREFIX}salaries/company${url}`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_COMPANY", response.data);
		// console.log('fetching employee',response.data)
        return response;
    });
}

const fetchEmployeeGraph = ({ commit }) =>{
	const route = router.currentRoute;
    const url = getQueryUrl(route.query);
    const {params} = route;
    const id = params.id
    commit("UPDATE_SPIN_GRAPH", true);
    if(parseInt(id) >= 0){
        return request({
            url: `${PRIVATE_API_PREFIX}salaries/${id}/total-employee-balances${url}`,
            method: "get",
            data: {},
            }).then((response) => {
                commit("UPDATE_GRAPH", response.data);
                return response;
            }).finally(()=>{
                commit("UPDATE_SPIN_GRAPH", false);
            })
    } else {
        commit("UPDATE_SPIN_GRAPH", false);
    }
}


const fetchEmployeelist = ({ commit }, {id, sDate, eDate, employeetype}) =>{
	// const route = router.currentRoute;
    // const url = getQueryUrl(route.query);
    // const {params} = route;
    // const id = params.id
    commit('UPDATE_SPIN_LIST', true);
    sDate !== undefined ? sDate : sDate = ''
    eDate !== undefined ? eDate : eDate = ''
    employeetype !== undefined ? employeetype : employeetype = 1;
    if(id !== undefined){
        return request({
            url: `${PRIVATE_API_PREFIX}salaries/${id}/total-balance-papers?sdate=${sDate}&edate=${eDate}&employeetype=${employeetype}`,
            method: "get",
            data: {},
        }).then((response) => {
            commit("UPDATE_LIST", response.data);
            return response;
    
        }).finally(()=>{
            commit('UPDATE_SPIN_LIST', false);
        })
    } else {
        commit('UPDATE_SPIN_LIST', false);
    }
}

const fetchDataModal = ({ commit }) =>{
	const route = router.currentRoute;
    const {params} = route;
    const url = getQueryUrl(route.query);
    const id = params.id
    // sDate !== undefined ? sDate : sDate = ''
    // eDate !== undefined ? eDate : eDate = ''
    // employeetype !== undefined ? employeetype : employeetype = 1;
    return request({
        url: `${PRIVATE_API_PREFIX}salaries/${id}/col-amount${url}`,
        method: "get",
        data: {},
    }).then((response) => {
        commit("UPDATE_DATA_MODAL", response.data);
        return response;
    });
}

// ===============================================
function getQueryUrl(queries) {
    var url = "";
    var i = 0;
    Object.keys(queries).forEach((key, index) => {
        if (queries[key] != undefined) {
            i += 1;
            if (i == 1) {
                url += `?${key}=${queries[key]}`;
            } else {
                url += `&${key}=${queries[key]}`;
            }
        }
    });
    return url;
}



export default {
	fetUsers,
	fetchEmployee,
    fetchEmployeeGraph,
    fetchEmployeelist,
    fetchCompany,
    fetchDataModal
};
