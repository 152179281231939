import request from '@/utils/request';
import router from '@/router'
import {PRIVATE_API_PREFIX} from "@/utils/api_prefix";

const fetchUsers = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	let url = '';
	if(query.page){
		url = '?page=' + query.page;
	}

	return  request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	})
	.then((result) => {
		if (result.status === 200) {
			commit('UPDATE_USER', result.data)
			commit('UPDATE_PAGINATION',result.meta)
		}
	});
};
const fetchLeaders = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders/leaders`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_LEADER', response.data);
		}
		return response;
	}).catch((errors) => {
		return errors;
	});
};

const fetchCompanies = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders/companies`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_COMPANY', response.data);
			dispatch('fetchAgentsLeader');
			dispatch('fetchLotteries');
			dispatch('fetchAgentCheckBySeries');
		}
		return response;
	}).catch((errors) => {
		return errors;
	});
};

const fetchLotteries = ({ commit }, payload) => {
	let url = '';
	if (payload != undefined && payload != '') url += `?param=${payload}`
	commit('UPDATE_SPINNING', true);
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders/series${url}`,
		method: 'get',
		data: { 
			payload
		},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_LOTTERY', response.data);
			commit('UPDATE_SPINNING', false);
		}
		return response;
	}).catch((errors) => {
		return errors;
	}).finally(() => {
		commit('UPDATE_SPINNING', false);
	});
	
};
const fetchAgentsLeader = ({ commit }) => {
	const param = router.currentRoute;
	let url = getQueryUrl(param.query);
	commit('UPDATE_SPINNING', true);
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_AGENT_LEADER', response.data);
			commit('UPDATE_SPINNING', false);
		}
		return response;
	}).catch((errors) => {
		return errors;
	}).finally(() => {
		commit('UPDATE_SPINNING', false);
	});
};
const createAgentLeader = ({ dispatch }, {teamLeader, checked, users}) => {
	const {query} = router.currentRoute;
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders`,
		method: 'post',
		data: {
			teamLeader, 
			checked:JSON.stringify(checked),
			users:JSON.stringify(users),
			series_id: query.series_id
		}
	}).then((response) => {
		if (response.status === 200) {
			dispatch('fetchAgentsLeader');
		}
		return response;
	}).catch((errors) => {
		return errors;
	});
};
const editAgentLeader = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders/` + payload + "/edit",
		method: 'get',
		data: payload
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_AGENT', response.data)
			commit('UPDATE_AGENT_ID', payload),
			commit('UPDATE_AGENT_IDS', payload)
		}
	})
};
const updateAgentLeader = ({ commit,dispatch }, {teamLeader,checked, users, new_commision, deletedAgents}) => {
	const {query} = router.currentRoute;
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders/${teamLeader.id}`,
		method: 'put',
		data: {
			teamLeader,
			checked:JSON.stringify(checked),
			users:JSON.stringify(users),
			new_commision:JSON.stringify(new_commision),
			deletedAgents:JSON.stringify(deletedAgents),
			series_id: query.series_id
		}
	}).then((response) => {
		if (response.status === 200) {
			dispatch('fetchAgentsLeader');
			commit('UPDATE_AGENT_IDS', 0);
		}
		return response;
	}).catch(errors => {
		return errors;
	})
};

const fetchAgentCheckBySeries = ({ commit }, payload) => {
	const { query, params } = router.currentRoute;
	const url = params.id ? `?agent_id=${params.id}` : '';
	var q = getQueryUrl(query);
	q =  q.replace('?', '&');

	const series_id = payload || query.series_id
	commit('UPDATE_SPIN', true);
	if(series_id && params.id){
		return request({
			url: `${PRIVATE_API_PREFIX}agent-leaders/${series_id}/agents-by-series${url}${q}`,
			method: 'get',
			data: payload,
		}).then((response) => {
			if (response.status === 200) {
				const {agents,agentsBySery, approvedAgents } = response.data;
				commit('UPDATE_USER', agents);
				commit('UPDATE_AGENT_CHECKED', agentsBySery);
				commit('UPDATE_APPROVED_AGENTS', approvedAgents);
				commit('UPDATE_SERY_ID', payload);
				commit('UPDATE_SPIN', false);
			}
			return response;
		}).catch((errors) => {
			return errors;
		}).finally(()=> {
			commit('UPDATE_SPIN', false);
		});
	}else {
		commit('UPDATE_SPIN', false);
	}
};

const fetchAgentBySeries = ({ commit }, payload) => {
	const { query, params } = router.currentRoute;
	const url = params.id ? `?agent_id=${params.id}` : '';
	const series_id = payload || query.series_id
	commit('UPDATE_SPIN', true);
	if(series_id || params.id){
		return request({
			url: `${PRIVATE_API_PREFIX}agent-leaders/${series_id}/agents-by-series${url}`,
			method: 'get',
			data: payload,
		}).then((response) => {
			if (response.status === 200) {
				commit('UPDATE_USER', response.data.agents);
				commit('UPDATE_AGENT_CHECKED', response.data.agentsBySery);
				commit('UPDATE_SERY_ID', payload);
				commit('UPDATE_SPIN', false);
			}
			return response;
		}).catch((errors) => {
			return errors;
		}).finally(()=> {
			commit('UPDATE_SPIN', false);
		});
	}else {
		commit('UPDATE_SPIN', false);
	}
};

const fetchAgentCreate = ({ commit }, payload) => {
	const { query, params } = router.currentRoute;
	const url = params.id ? `?agent_id=${params.id}` : '';
	const series_id = payload || query.series_id
	commit('UPDATE_SPIN', true);
	if(series_id){
		return request({
			url: `${PRIVATE_API_PREFIX}agent-leaders/${series_id}/agents-by-series${url}`,
			method: 'get',
			data: payload,
		}).then((response) => {
			if (response.status === 200) {
				commit('UPDATE_USER', response.data.agents);
				commit('UPDATE_AGENT_CHECKED', response.data.agentsBySery);
				commit('UPDATE_SERY_ID', payload);
				commit('UPDATE_SPIN', false);
			}
			return response;
		}).catch((errors) => {
			return errors;
		}).finally(()=> {
			commit('UPDATE_SPIN', false);
		});
	}else {
		commit('UPDATE_SPIN', false);
	}
};

const fetchVersionHistories = ({ commit }, payload) => {
	commit('UPDATE_MODAL_LOADING', true);
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders/${payload}/version-histories${url}`,
		method: 'get',
		data: payload
	}).then((response) => {
		const { status, data } = response;
		if (status === 200) {
			commit('UPDATE_VERSION_HISTORY', data);
			commit('UPDATE_MODAL_LOADING', false);
		}
	}).finally(() => {
		commit('UPDATE_MODAL_LOADING', false);
	})
};

const approveAgentLeaderCommission = ({ commit,dispatch }, {teamLeader,checked, users}) => {
	const {query} = router.currentRoute;
	return request({
		url: `${PRIVATE_API_PREFIX}agent-leaders/${teamLeader.id}/approve`,
		method: 'post',
		data: {
			teamLeader,
			checked:JSON.stringify(checked),
			users:JSON.stringify(users),
			series_id: query.series_id
		}
	}).then((response) => {
		if (response.status === 200) {
			dispatch('fetchAgentsLeader');
			commit('UPDATE_AGENT_IDS', 0);
		}
		return response;
	}).catch(errors => {
		return errors;
	})
};

function getQueryUrl(queries){
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key,index) => {
		if(queries[key] != undefined){
			i+=1;
			if(i == 1){
			url += `?${key}=${queries[key]}`;
			} else {
			url += `&${key}=${queries[key]}`;
			}
		} 
	})
	return url;
}

export default {
	fetchUsers,
	fetchLeaders,
	fetchLotteries,
	createAgentLeader,
	fetchAgentsLeader,
	editAgentLeader,
	updateAgentLeader,
	fetchAgentCheckBySeries,
	fetchCompanies,
	fetchAgentCreate,
	fetchVersionHistories,
	fetchAgentBySeries,
	approveAgentLeaderCommission
};
