<template>
	<div id="app" class="overflow-hidden">
		<div class="f-stars overflow-hidden">
			<a-layout class="min-h-screen overflow-hidden">
				<template v-if="$route.name && $route.name == 'admin.login'">
					<router-view :key="$route.name" />
				</template>
				<template v-else-if="$route.name">
					<header-layout
						class="overflow-hidden"
						v-if="$route.name == 'admin.dashboard'"
					></header-layout>
					<a-layout class="overflow-hidden">
						<side-bar-layout
							class="overflow-hidden"
						></side-bar-layout>
						<a-layout-content>
							<router-view :key="$route.meta.id" />
						</a-layout-content>
					</a-layout>
				</template>
			</a-layout>
		</div>
		<div class="calc-report-progress flex items-center" v-if="progressReport && progressReport.amount != null">
			<span @click="toggleProgress()">
				<fs-icon
					:type="[
						swipe === false
							? 'ic:outline-arrow-right'
							: 'ic:outline-arrow-left',
					]"
					:key="`${swipe ? 1 : 2}`"
				></fs-icon>
			</span>
			<span class="pr-2">{{ progressReport.amount }}</span>
		</div>
	</div>
</template>

<script>
	import HeaderLayout from '@/views/layouts/header_layout.vue';
	import SideBarLayout from '@/views/layouts/sidebar_layout.vue';
	import { mapGetters, mapActions, mapMutations } from 'vuex';
	export default {
		components: {
			HeaderLayout,
			SideBarLayout,
		},
		data() {
			return {
				collapsed: true,
				newclass: '',
				matched: false,
				swipe: false,
				proReport: null,
				sendRequest: []
			};
		},
		created() {
			const html = document.documentElement;
			const lang = this.$cookies.get('locale');
			html.setAttribute('lang', lang);

			// document.addEventListener('contextmenu', function (e) {
			// 	e.preventDefault();
			// });

			// document.onkeydown = function (e) {
			// 	if (e.which == 123) {
			// 		return false;
			// 	}
			// };
		},
		methods: {
			...mapActions(['getAuth', 'queueCalcPaperBalance', 'fetchMenus', 'autoUpload']),
			...mapActions(['fetchReportProgress']),
			...mapMutations(['UPDATE_PROGRESS_REPORT']),
			async handleFetchAuth() {
				const isLogin = this.$cookies.get('authenticated');
				const cRoute = this.$router.currentRoute;

				if (isLogin && cRoute.name != 'admin.login') {
					await this.getAuth();
				}
			},
			async handleFetchUserMenu() {
				await this.fetchMenus().then(() => {
					this.handleFilterRoute();
				});
			},
			async handleFilterRoute() {
				const { name } = this.$router.currentRoute;
				const matched = await this.menus.filter((ob, i) => {
					return ob.param.route == name;
				});

				if (name != 'admin.login' && !matched.length) {
					this.$router
						.push({ name: '403', query: {}, params: {} })
						.catch(() => {});
				}
			},
			toggleProgress() {
				this.swipe = !this.swipe;
				const el = document.getElementsByClassName(
					'calc-report-progress'
				);
				if (el && el.length) {
					const width = el[0].clientWidth - 24;
					if (this.swipe) el[0].style.right = -width + 'px';
					else el[0].style.right = 2 + 'px';
				}
			},
		},
		mounted() {
			// this.handleFetchUserMenu();
			this.handleFetchAuth();

			// const data = {
			// 	image_path: 'https://www.collinsdictionary.com/images/full/paper_111691001.jpg',
			// 	image_name: 'test.jpg',
			// 	group_line_id: 'Cae05e1c7acff24df29e65e46672e424b'
			// };

			// setInterval(()=>{
			// 	this.autoUpload(data);
			// }, 10000);
		},
		updated(){
			this.sendRequest = [];
			
			// this.UPDATE_PROGRESS_REPORT(0);
			const { name } = this.$router.currentRoute;
			const routes = this.allowProgressRoutes;
			this.sendRequest = routes.filter((route)=>{
				return route === name;
			});
			// if(name == 'admin.login'){
			// 	clearInterval(this.proReport);
			// }
			if(!this.sendRequest.length){
				this.UPDATE_PROGRESS_REPORT(0);
			}
		},
		computed: {
			...mapGetters(['menus', 'auth']),
			...mapGetters(['progressReport', 'allowProgressRoutes'])
		},
		watch: {
			'$route': {
				handler: function(to, from) {
					this.$nextTick(() => {
						document.title = `Metaluckymoon | ${to.meta.title}`;
						//fetch progress report
						if((to && to.name) != (from && from.name)){
							const vm = this

							clearInterval(this.proReport);

							if((this.sendRequest && this.sendRequest.length > 0) && (this.auth && this.auth.type == 'admin')){
								this.fetchReportProgress();
								this.proReport = setInterval(() => {
									if(vm.sendRequest && vm.sendRequest.length > 0){
										vm.fetchReportProgress();
									}
								}, 2000);
							}
						}
					});
				},
				deep: true,
				immediate: true
			},
			menus(obj) {
				if (obj.length) {
					const { name } = this.$router.currentRoute;
					var index = 0;
					var length = obj.length;
					obj.filter((ob, i) => {
						index++;

						if (ob.param.route == name) {
							this.matched = true;
						}
					});

					setTimeout(() => {
						if (!this.matched && index == length && name != '404') {
							this.$router
								.push({ name: '403', query: {}, params: {} })
								.catch(() => {});
						}
					}, 500);
				} else {
					this.$router
						.push({ name: '403', query: {}, params: {} })
						.catch(() => {});
				}
			},
		},
		destroyed(){
			clearInterval(this.proReport);
		}
	};
</script>

<style>
	main {
		width: 100%;
	}
	.calc-report-progress {
		position: fixed;
		bottom: 2px;
		right: 2px;
		min-width: 30px;
		padding: 0;
		border-radius: 3px;
		background: rgb(239 239 185);
		font-weight: 600;
		font-size: 12px;
		color: #797404;
	}
</style>
