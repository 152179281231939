const UPDATE_LOADING = (state, payload) => {
	state.loading = payload;
};

const UPDATE_AGENT = (state, payload) => {
	state.agents = payload;
}

const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
}

const UPDATE_SERIES_SHIFTS = (state,payload)=>{
	state.series_shifts = payload;
}

export default {
	UPDATE_LOADING,
	UPDATE_AGENT,
	UPDATE_SERIES,
	UPDATE_SERIES_SHIFTS,
};
