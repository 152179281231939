const UPDATE_LOADING = (state, payload) => {
    state.loading = payload;
};
const UPDATE_COMPANIES = (state, payload) => {
    state.companies = payload;
};
const UPDATE_COMPANY = (state, payload) => {
    state.company = payload;
};
const UPDATE_USERS_OWNER = (state, payload) => {
    state.usersOwner = payload;
};
const UPDATE_AGENT_LEADER = (state, payload) => {
    state.agentLeader = payload;
};
const UPDATE_AGENT_CHECKED = (state, payload) => {
    state.checked = payload;
};
const UPDATE_LOADING_SAVE = (state, payload) => {
    state.btnSave = payload;
};
const UPDATE_SPIN = (state, payload) => {
    state.spin = payload;
};

const UPDATE_VISIBLE_MODAL = (state, payload) => {
    state.visible = payload;
};

const UPDATE_COMPANY_VERSION = (state, payload) => {
    state.versions = payload;
};

const UPDATE_MODAL_LOADING = (state, payload) => {
    state.loadingModal = payload;
};

export default {
    UPDATE_LOADING,
    UPDATE_COMPANIES,
    UPDATE_USERS_OWNER,
    UPDATE_COMPANY,
    UPDATE_AGENT_LEADER,
    UPDATE_AGENT_CHECKED,
    UPDATE_LOADING_SAVE,
    UPDATE_SPIN,
    UPDATE_VISIBLE_MODAL,
    UPDATE_COMPANY_VERSION,
    UPDATE_MODAL_LOADING
};
