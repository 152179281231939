import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetchUsers = ({ commit }, payload) => {
	commit('UPDATE_USER_SPIN', true);
	const { query } = router.currentRoute;
	let url = '';
	if (query.param) {
		url = `?param=${query.param}`;
	}

	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: payload,
	})
		.then((response) => {
			if (response.status === 200) {
				commit('UPDATE_USER', response.data);
				commit('UPDATE_USER_SPIN', false);
			}
			return response;
		})
		.finally(() => {
			commit('UPDATE_USER_SPIN', false);
		});
};

const fetchUserRoles = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/roles`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_USER_ROLE', response.data);
		}
		return response;
	});
};

const fetchCompanies = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/companies`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_COMPANIES', response.data);
		}
		return response;
	});
};

const fetchUserTypes = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/types`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_USER_TYPE', response.data);
		}
		return response;
	});
};

const fetchUserGenders = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/genders`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_GENDER', response.data);
		}
		return response;
	});
};

const fetchStatuses = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/statuses`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_STATUS', response.data);
		}
		return response;
	});
};

const deleteUser = ({ dispatch }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/${payload}`,
		method: 'delete',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			dispatch('fetchUsers');
		}
		return response;
	});
};

const toggleModal = ({ commit }, payload) => {
	commit('UPDATE_MODAL', payload);
};

const saveUser = ({ dispatch }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users`,
		method: 'post',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			dispatch('fetchUsers');
		}
		return response;
	});
};

const updateUser = ({ dispatch }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/${payload.id}`,
		method: 'put',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			dispatch('fetchUsers');
		}
		return response;
	});
};

const editUser = ({ commit, dispatch }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/${payload}/edit`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_EDIT_USER', response.data);
		}
		return response;
	});
};

const setUserMenu = ({ commit, dispatch }, payload) => {
	const { params } = router.currentRoute;
	if (params && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-menus`,
			method: 'post',
			data: { values: JSON.stringify(payload) },
		}).then((response) => {
			if (response.status === 200) {
				dispatch('fetchMenus', {}, { root: true });
			}
			return response;
		});
	}
};

const fetchGroupWorks = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/setting-teams`,
		method: 'get',
		data: payload,
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_GROUP_WORK', response.data);
		}
		return response;
	});
};

const setGroupWork = ({ dispatch }, payload) => {
	const { params } = router.currentRoute;
	if (params && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-teams`,
			method: 'post',
			data: { values: JSON.stringify(payload) },
		}).then((response) => {
			return response;
		});
	}
};

const fetchSelectedGroupWorkByUser = ({ commit }, payload) => {
	const { params } = router.currentRoute;
	if (params.id >= 0) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-teams`,
			method: 'get',
			data: payload,
		}).then((response) => {
			return response;
		});
	}
};

const fetchSelectedMenuByUser = ({ commit }, payload) => {
	const { params } = router.currentRoute;
	if (params.id >= 0) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-menus`,
			method: 'get',
			data: payload,
		}).then((response) => {
			return response;
		});
	}
};

const fetchUserCommissions = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/${payload}/setting-commissions`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_USER_COMMISSION', response.data);
			return response;
		}
	});
};

const fetchUserPrizes = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users/${payload}/setting-prizes`,
		method: 'get',
		data: {},
	}).then((response) => {
		if (response.status === 200) {
			commit('UPDATE_PRIZE', response.data);
			return response;
		}
	});
};

const saveCommissionSetting = ({ dispatch }, payload) => {
	const { params } = router.currentRoute;
	if (params.id != undefined && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-commissions`,
			method: 'post',
			data: {
				commissions: JSON.stringify(payload.commission),
				renew_commission: JSON.stringify(payload.renew_commission),
			},
		}).then((response) => {
			return response;
		});
	}
};

const approvePrizetting = ({ dispatch }, payload) => {
	const { params } = router.currentRoute;
	if (params.id != undefined && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/approve-prizes`,
			method: 'post',
			data: {
				prizes: JSON.stringify(payload),
			},
		}).then((response) => {
			return response;
		});
	}
};

const approveCommissionSetting = ({ dispatch }, payload) => {
	const { params } = router.currentRoute;
	if (params.id != undefined && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/approve-commissions`,
			method: 'post',
			data: {
				commissions: JSON.stringify(payload),
			},
		}).then((response) => {
			return response;
		});
	}
};

const savePrizeSetting = ({ dispatch }, payload) => {
	const { params } = router.currentRoute;
	if (params.id != undefined && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-prizes`,
			method: 'post',
			data: {
				prizes: JSON.stringify(payload.prize),
				renew_prize: JSON.stringify(payload.renew_prize),
			},
		}).then((response) => {
			return response;
		});
	}
};

const getAgentLeaders = ({ commit }, payload) => {
	const { params, query } = router.currentRoute;
	const url = getQueryUrl(query);
	if (params.id >= 0) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-users${url}`,
			method: 'get',
			data: payload,
		}).then((response) => {
			if (response.status === 200) {
				// commit('UPDATE_AGENT_LEADER', response.data);
				commit('UPDATE_USER_SETTING', response.data);
			}
			return response;
		});
	} else {
		// commit('UPDATE_AGENT_LEADER', []);
		commit('UPDATE_USER_SETTING', []);
	}
};

const saveUserSetting = ({ commit }, payload) => {
	const { params } = router.currentRoute;
	const { users, deletedUsers } = payload;
	if (params.id != undefined && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/setting-users`,
			method: 'post',
			data: {
				users: JSON.stringify(users),
				deletedUsers: JSON.stringify(deletedUsers),
			},
		}).then((response) => {
			return response;
		});
	}
};

const getSelectedUserSettings = ({ commit }, payload) => {
	const { params } = router.currentRoute;
	if (params && params.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${params.id}/selected-setting-users`,
			method: 'get',
			data: { users: JSON.stringify(payload) },
		}).then((response) => {
			return response;
		});
	}
};

const fetchEligibleUsers = ({ commit }, payload) => {
	commit('UPDATE_ELIGIBLE_SPIN', true);
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);

	return request({
		url: `${PRIVATE_API_PREFIX}users/eligible-users${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			const { status, data } = response;
			if (status === 200) {
				commit('UPDATE_ELIGIBLE_USER', data);
				commit('UPDATE_ELIGIBLE_SPIN', false);
			}
		})
		.finally(() => {
			commit('UPDATE_ELIGIBLE_SPIN', false);
		});
};

const fetchCommissionPrizeHistory = ({ commit }, payload) => {
	commit('UPDATE_VERSION_HISTORY_SPINNING', true);
	const { type, user_id } = payload;

	return request({
		url: `${PRIVATE_API_PREFIX}users/${user_id}/commission-prizes?type=${type}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			const { status, data } = response;
			if (status === 200) {
				commit('UPDATE_VERSION_HISTORY_SPINNING', false);
				if (type == 1)
					commit('UPDATE_COMMISSION_VERSION_HISTORY', data);
				else if (type == 2)
					commit('UPDATE_PRIZE_VERSION_HISTORY', data);
			}
		})
		.finally(() => {
			commit('UPDATE_VERSION_HISTORY_SPINNING', false);
		});
};

const fetchEmployees = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	const { id } = router.currentRoute.params;
	const idx = payload ? payload : id;

	return request({
		url: `${PRIVATE_API_PREFIX}users/${idx}/employees${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			const { status, data } = response;
			if (status === 200) {
				commit('UPDATE_EMPLOYEE', data);
			}
			return response;
		})
		.finally(() => {});
};

const fetchAgents = ({ commit }, payload) => {
	const { id } = router.currentRoute.params;
	const url = payload ? `?transfer_id=${payload}` : '';

	commit('UPDATE_AGENT_LOADING', true);
	return request({
		url: `${PRIVATE_API_PREFIX}users/${id}/agents${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			const { status, data } = response;
			if (status === 200) {
				commit('UPDATE_AGENT', data);
				commit('UPDATE_AGENT_LOADING', false);
			}
			return response;
		})
		.finally(() => {
			commit('UPDATE_AGENT_LOADING', false);
		});
};

const storeTranferData = ({ commit, dispatch }, payload) => {
	const { query } = router.currentRoute;
	const url = getQueryUrl(query);
	return request({
		url: `${PRIVATE_API_PREFIX}users/tranfers${url}`,
		method: 'post',
		data: {
			owner_id: payload.owner_id,
			agents: JSON.stringify(payload.agents),
			start_date: payload.start_date,
			end_date: payload.end_date,
			employee: payload.employee,
		},
	}).then((response) => {
		return response;
	});
};

const fetchAgentGraphData = ({ commit }, payload) => {
	const { id } = router.currentRoute.params;
	const userId = payload || id;
	if (userId) {
		return request({
			url: `${PRIVATE_API_PREFIX}users/${userId}/agent-reports`,
			method: 'get',
			data: {},
		}).then((response) => {
			const { status, data } = response;
			if (status === 200) {
				commit('UPDATE_AGENT_GRAPH_DATA', data.data);
				commit('UPDATE_AGENT_GRAPH_NOTE', data.note);
			}
			return response;
		});
	}
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchUsers,
	deleteUser,
	toggleModal,
	saveUser,
	updateUser,
	editUser,
	fetchUserRoles,
	fetchUserTypes,
	fetchUserGenders,
	fetchStatuses,
	setUserMenu,
	fetchGroupWorks,
	setGroupWork,
	fetchSelectedGroupWorkByUser,
	fetchSelectedMenuByUser,
	fetchUserCommissions,
	fetchUserPrizes,
	saveCommissionSetting,
	savePrizeSetting,
	getAgentLeaders,
	saveUserSetting,
	getSelectedUserSettings,
	fetchCompanies,
	fetchEligibleUsers,
	fetchCommissionPrizeHistory,
	fetchEmployees,
	fetchAgents,
	storeTranferData,
	approveCommissionSetting,
	fetchAgentGraphData,
	approvePrizetting
};
