const state = {
    loading: false,
    userGroupWork: [],
    groupWorks: [],
    groupWorksMembers: [],

    team: {
        user_group_name: "",
        user_id: undefined,
    },
};

export default state;
