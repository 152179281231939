const UPDATE_LOADING = (state, payload) => {
    state.loading = payload;
};

const UPDATE_SERIES = (state, payload) => {
    state.series = payload;
};

const UPDATE_AGENT_LEADERS = (state, payload) => {
    state.agentLeaders = payload;
};

const UPDATE_AGENTS = (state, payload) => {
    state.agents = payload;
};

const UPDATE_AGENT_SPIN = (state, payload) => {
    state.agentSpin = payload;
};

const UPDATE_AGENT_BOOKPAYMENT = (state, payload) => {
    state.showBookPayments = payload;
};

const UPDATE_COMPANIES= (state, payload) => {
    state.companies = payload;
};
const UPDATE_LEADER_ID= (state, payload) => {
    state.leaderId = payload;
};
const UPDATE_SERY_ID= (state, payload) => {
    state.seryId = payload;
};
const UPDATE_COMPANY_ID= (state, payload) => {
    state.isActiveCompany = payload;
};
const UPDATE_AGENT_LEADER_ID = (state, payload) => {
    state.isActiveAgentLeader = payload;
};

const UPDATE_SPIN_FORM = (state, payload) => {
    state.spinForm = payload;
};

const UPDATE_ALL_SERIES = (state, payload) => {
    state.allSeries = payload;
};

export default {
    UPDATE_LOADING,
    UPDATE_SERIES,
    UPDATE_AGENT_LEADERS,
    UPDATE_AGENTS,
    UPDATE_AGENT_BOOKPAYMENT,
    UPDATE_AGENT_SPIN,
    UPDATE_COMPANIES,
    UPDATE_LEADER_ID,
    UPDATE_SERY_ID,
    UPDATE_COMPANY_ID,
    UPDATE_AGENT_LEADER_ID,
    UPDATE_SPIN_FORM,
    UPDATE_ALL_SERIES
};
