const state = {
    drawerVisible: false,
    isCombine: true,
    employees: [],
    company: [],
    graph: [],
    nodate: true,
    list: [],
    sDate: null,
    eDate: null,
    isViewer: false,
    modal: false,
    dataModal:[],
    listSpiner: false,
    loadingGraph: false,
    loadingUser: false,
    empTypes: [
        {
            id: '1',
            name: 'វាយបញ្ចូល',
        },
        {
            id: '2',
            name: 'បូកដៃ និង បញ្ចូលជេីងក្រោម',
        }
    ],
    empType: undefined
};

export default state;
