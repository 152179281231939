import request from '@/utils/request';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';
import router from '@/router';

const fetchUsers = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		return reponse;
	});
};

const fetchAllLotProviders = ({ commit }, payload) => {
	commit('UPDATE_PROVIDER_SPINING', true);
	const params = router.currentRoute;
	const url = getQueryUrl(params.query);
	return request({
		url: `${PRIVATE_API_PREFIX}lot-managements${url}`,
		method: 'get',
		data: {},
	})
		.then((result) => {
			if (result.status == 200) {
				commit('UPDATE_PROVIDER_SPINING', false);
				commit('UPDATE_LOTTERY_PROVIDERS', result.data);
				if (!result.data.length)
					commit('UPDATE_LOTTERY_TIME_TYPES', []);
			}
			return result;
		})
		.finally(() => {
			commit('UPDATE_PROVIDER_SPINING', false);
		});
};
const fetchAllLotProvidersCycles = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}lot-managements/lot-providers-cycles`,
		method: 'get',
		data: {},
	}).then((result) => {
		if (result.status == 200) {
			commit('UPDATE_LOTTERY_PROVIDERS_CYCLES', result.data);
		}
		return result;
	});
};
const fetchAllLotTimeType = ({ commit }, payload) => {
	commit('UPDATE_SPINING', true);
	const params = router.currentRoute;
	const id = params.params.id != undefined ? params.params.id : payload;
	if (id && payload) {
		return request({
			url: `${PRIVATE_API_PREFIX}lot-managements/${id}/lot-times`,
			method: 'get',
			data: {},
		})
			.then((result) => {
				if (result.status == 200) {
					commit('UPDATE_SPINING', false);
					commit('UPDATE_LOTTERY_TIME_TYPES', result.data);
				}
				return result;
			})
			.finally(() => {
				commit('UPDATE_SPINING', false);
			});
	}
};
const fetchAllLotPostDetails = ({ commit }, payload) => {
	commit('UPDATE_PROVIDER_DETAIL_SPINING', true);
	const params = router.currentRoute;
	const id = params.params.id ? params.params.id : payload;
	const url = getQueryUrl(params.query);
	if (id && payload) {
		return request({
			url: `${PRIVATE_API_PREFIX}lot-managements/post-details/${id}${url}`,
			method: 'get',
			data: {},
		})
			.then((result) => {
				if (result.status == 200) {
					commit('UPDATE_PROVIDER_DETAIL_SPINING', false);
					commit('UPDATE_LOTTERY_POST_DETAILS', result.data);
				}
				return result;
			})
			.finally(() => {
				commit('UPDATE_PROVIDER_DETAIL_SPINING', false);
			});
	}
};

const storeLotteryProvider = ({ dispatch }, payload) => {
	return request({
		method: 'post',
		url: `${PRIVATE_API_PREFIX}lot-managements`,
		data: payload,
	})
		.then((result) => {
			if (result.status == 200) {
				dispatch('fetchAllLotProviders', result.data);
			}
			return result;
		})
		.catch((errors) => {
			return errors;
		});
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchUsers,
	fetchAllLotProviders,
	fetchAllLotProvidersCycles,
	storeLotteryProvider,
	fetchAllLotTimeType,
	fetchAllLotPostDetails,
};
