const UPDATE_USER = (state, payload) => {
	state.users = payload;
};

const UPDATE_MODAL = (state, payload) => {
	state.showModal = payload;
};

const UPDATE_EDIT_USER = (state, payload) => {
	state.user = payload;
};

const UPDATE_USER_DRAWER = (state, payload) => {
	state.drawerVisable = payload;
};

const UPDATE_GENDER = (state, payload) => {
	state.genders = payload;
};

const UPDATE_USER_TYPE = (state, payload) => {
	state.userTypes = payload;
};

const UPDATE_USER_ROLE = (state, payload) => {
	state.roles = payload;
};

const UPDATE_STATUS = (state, payload) => {
	state.statuses = payload;
};

const UPDATE_GROUP_WORK = (state, payload) => {
	state.groupWorks = payload;
};

const UPDATE_USER_COMMISSION = (state, payload) => {
	state.commissions = payload;
};

const UPDATE_PRIZE = (state, payload) => {
	state.prizes = payload;
};

const UPDATE_AGENT_LEADER = (state, payload) => {
	state.agentLeaders = payload;
};

const UPDATE_RESET_USER = (state, payload) => {
	state.user = payload;
};

const UPDATE_USER_TEM_ID = (state, payload) => {
	state.temId = payload;
};

const UPDATE_USER_ROLE_TYPE = (state, payload) => {
	state.userRoleType = payload;
};

const UPDATE_USER_SPIN = (state, payload) => {
	state.userSpinning = payload;
};

const UPDATE_COMPANIES = (state, payload) => {
	state.companies = payload;
};

const UPDATE_ELIGIBLE_USER = (state, payload) => {
	state.eliUsers = payload;
};

const UPDATE_ELIGIBLE_SPIN = (state, payload) => {
	state.eligibleSpin = payload;
};

const UPDATE_TOTAL_USER = (state, payload) => {
	state.totalUser = payload;
};

const UPDATE_COMMISSION_MODAL = (state, payload) => {
	state.commissionVisible = payload;
};

const UPDATE_PRIZE_MODAL = (state, payload) => {
	state.prizeVisible = payload;
};

const UPDATE_COMMISSION_VERSION_HISTORY = (state, payload) => {
	state.commissionVersions = payload;
};

const UPDATE_PRIZE_VERSION_HISTORY = (state, payload) => {
	state.prizeVersions = payload;
};

const UPDATE_VERSION_HISTORY_SPINNING = (state, payload) => {
	state.versionLoading = payload;
};

const UPDATE_EMPLOYEE = (state, payload) => {
	state.employees = payload;
};

const UPDATE_AGENT = (state, payload) => {
	state.agents = payload;
};

const UPDATE_AGENT_LOADING = (state, payload) => {
	state.agentLeading = payload;
};

const UPDATE_USER_SETTING = (state, payload) => {
	state.plainOptions = payload;
};

const UPDATE_AGENT_GRAPH_DATA = (state, payload) => {
	state.agentGraphData = payload;
};

const UPDATE_AGENT_GRAPH_NOTE = (state, payload) => {
	state.agentGraphNotes = payload;
};

export default {
	UPDATE_USER,
	UPDATE_MODAL,
	UPDATE_EDIT_USER,
	UPDATE_USER_DRAWER,
	UPDATE_GENDER,
	UPDATE_USER_TYPE,
	UPDATE_USER_ROLE,
	UPDATE_STATUS,
	UPDATE_GROUP_WORK,
	UPDATE_USER_COMMISSION,
	UPDATE_PRIZE,
	UPDATE_AGENT_LEADER,
	UPDATE_RESET_USER,
	UPDATE_USER_TEM_ID,
	UPDATE_USER_ROLE_TYPE,
	UPDATE_USER_SPIN,
	UPDATE_COMPANIES,
	UPDATE_ELIGIBLE_USER,
	UPDATE_ELIGIBLE_SPIN,
	UPDATE_TOTAL_USER,
	UPDATE_COMMISSION_MODAL,
	UPDATE_PRIZE_MODAL,
	UPDATE_COMMISSION_VERSION_HISTORY,
	UPDATE_PRIZE_VERSION_HISTORY,
	UPDATE_VERSION_HISTORY_SPINNING,
	UPDATE_EMPLOYEE,
	UPDATE_AGENT,
	UPDATE_AGENT_LOADING,
	UPDATE_USER_SETTING,
	UPDATE_AGENT_GRAPH_DATA,
	UPDATE_AGENT_GRAPH_NOTE
};
