const state = {
	showForm: false,
	users: [],
	agent: {
		id:'',
		agent_leader_name: '',
		user_id: ''
	},
	agents:[],
	lotteries: [],
	id: 0,
	agent_id: 0,
	leaders:[],
	sery_id: 0,
	lottery:[],
	user:[],
	checked: [],
	approvedIds: [],
	totalUsers: [],
	companies: [],
	spinning: false,
	spin: false,
	agentId: undefined,
	visible: false,
	versions: [],
	modalLoading: false
};



export default state;
