const UPDATE_USER = (state, payload) => {
	state.users = payload;
};

const UPDATE_AUTH = (state, payload) => {
	state.auth = payload;
};

const UPDATE_COLLAPSE_MENU = (state, payload) => {
	state.isCollapse = payload;
};

const UPDATE_OPENKEY = (state, payload) => {
	state.openKey = payload;
};

const UPDATE_SPIN = (state, payload) => {
	state.spin = payload;
};

const UPDATE_MENU = (state, payload) => {
	state.menus = payload;
};

const UPDATE_ALL_MENU = (state, payload) => {
	state.allMenus = payload;
};

const UPDATE_CURRENT_MENU = (state, payload) => {
	state.currentRouteName = payload;
};

const UPDATE_IS_VIEWER = (state, payload) => {
	state.isViewer = payload;
};

const UPDATE_TOGGLE_DISABLE_MENU = (state, payload) => {
	state.disableMenu = payload;
};

const UPDATE_PROGRESS_REPORT = (state, payload) => {
	state.progressReport = payload;
};

export default {
	UPDATE_USER,
	UPDATE_AUTH,
	UPDATE_COLLAPSE_MENU,
	UPDATE_OPENKEY,
	UPDATE_SPIN,
	UPDATE_MENU,
	UPDATE_ALL_MENU,
	UPDATE_CURRENT_MENU,
	UPDATE_IS_VIEWER,
	UPDATE_TOGGLE_DISABLE_MENU,
	UPDATE_PROGRESS_REPORT
};
