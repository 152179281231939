const UPDATE_SHOW_FORM = (state, payload) => {
	state.showForm = payload;
};
const UPDATE_AGENTS = (state, payload) => {
	state.agents = payload;
};
const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
};
const UPDATE_LINES = (state, payload) => {
	state.lines = payload;
};
const UPDATE_EDIT_LINE = (state, payload) => {
	state.line = payload;
};
const UPDATE_AMOUNTS = (state, payload) => {
	state.amounts = payload;
};
const UPDATE_LINE_ID = (state, payload) => {
	state.line_id = payload;
};
const UPDATE_ACTIVE_ID = (state, payload) => {
	state.activeLineId = payload;
};
const UPDATE_SERIES_ID = (state, payload) => {
	state.seriesName = payload;
};

export default {
	UPDATE_SHOW_FORM,
	UPDATE_AGENTS,
	UPDATE_SERIES,
	UPDATE_LINES,
	UPDATE_EDIT_LINE,
	UPDATE_AMOUNTS,
	UPDATE_LINE_ID,
	UPDATE_ACTIVE_ID,
	UPDATE_SERIES_ID
};
