const loading = (state) => state.loading;
const series = (state) => state.series;
const shifts = (state) => state.shifts;
const agents = (state) => state.agents;
const papers = (state) => state.papers;
const totals_papers = (state) => state.totals_papers;
const betResults = (state) => state.betResult;
const loadingForm = (state) => state.formLoading;
const dateForm = (state) => state.dateForm;
const dayForm = (state) => state.dayForm;

export default {
    loading,
    series,
    shifts,
    agents,
    papers,
    totals_papers,
    betResults,
    loadingForm,
    dateForm,
    dayForm,
};
