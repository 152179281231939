import user from "./user";
import groupLine from "./group_line";
import companyBookPayment from "./company_book_payment";
import agentLeaderBookPayment from "./agent_leader_book_payment";
import dashboard from "./dashboard";
import company from "./company";
import agent from "./agent";
import paper from "./paper";
import verifyNumber from "./verify_number";
import entrySery from "./entry_sery";
import lotManagement from "./lot_management";
import dailyCompanyResult from "./daily_company_result";
import dailyAgentResult from "./daily_agent_result";
import groupWork from "./group_work";
import columnEntry from "./column_entry";
import numberEntry from "./number_entry";
import checkFault from "./check_fault";
import uploadPaper from "./upload_paper";
import jobRun from "./job_run";
import salary from "./salary";
import referenceList from "./reference_list";
import agentLeaderReport from "./agent_leader_report";
import companyFinalReport from "./company_final_report";

export default { 
    user, 
    groupLine, 
    companyBookPayment,
    agentLeaderBookPayment,
    dashboard,
    company,
    agent,
    paper,
    verifyNumber,
    entrySery,
    lotManagement,
    dailyCompanyResult,
    dailyAgentResult,
    groupWork,
    columnEntry,
    numberEntry,
    checkFault,
    uploadPaper,
    jobRun,
    salary,
    referenceList,
    agentLeaderReport,
    companyFinalReport
}