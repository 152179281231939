const UPDATE_SHOW_FORM = (state, payload) => {
	state.showForm = payload;
};
const UPDATE_AGENT_LEADERS = (state, payload) => {
	state.showAgentLeaders = payload;
};
const UPDATE_SERIES = (state, payload) => {
	state.showAllSeries = payload;
};
const UPDATE_ALL_COMPANIES = (state, payload) => {
	state.showAllCompanies = payload;
};

const UPDATE_ALL_BOOKPAYMENTS = (state, payload) => {
	state.showBookPayments = payload;
};

const UPDATE_SPINING = (state, payload) => {
	state.isSpining = payload;
};

const UPDATE_LOADING = (state, payload) => {
	state.loading = payload;
};

const UPDATE_ALL_SERIES = (state, payload) => {
	state.allSeries = payload;
};

export default {
	UPDATE_SHOW_FORM,
	UPDATE_AGENT_LEADERS,
	UPDATE_SERIES,
	UPDATE_ALL_COMPANIES,
	UPDATE_ALL_BOOKPAYMENTS,
	UPDATE_SPINING,
	UPDATE_LOADING,
	UPDATE_ALL_SERIES
};
