const UPDATE_SHOW_FORM = (state, payload) => {
	state.showForm = payload;
};
const UPDATE_AGENT_LEADER = (state, payload) => {
	state.agents = payload;
};
const UPDATE_USER = (state, payload) => {
	state.users = payload;
};
const UPDATE_AGENT = (state, payload) => {
	state.agent = payload;
};
const UPDATE_LEADER = (state, payload) => {
	state.leaders = payload;
};
const UPDATE_LOTTERY = (state, payload) => {
	state.lotteries = payload;
};
const UPDATE_AGENT_ID = (state, payload) => {
	state.agent_id = payload;
};
const UPDATE_SERY_ID = (state, payload) => {
	state.sery_id = payload;
};
const UPDATE_AGENT_BY_SERIES = (state, payload) => {
	state.user = payload;
};
const UPDATE_AGENT_CHECKED = (state, payload) => {
	state.checked = payload;
};
const UPDATE_APPROVED_AGENTS = (state, payload) => {
	state.approvedIds = payload;
};
const UPDATE_TOTAL_USERS = (state, payload) => {
	state.totalUsers = payload;
};
const UPDATE_COMPANY= (state, payload) => {
	state.companies = payload;
};
const UPDATE_SPINNING= (state, payload) => {
	state.spinning = payload;
};
const UPDATE_SPIN= (state, payload) => {
	state.spin = payload;
};
const UPDATE_AGENT_IDS = (state, payload) => {
	state.agentId = payload;
};
const UPDATE_CLEAR_FORM = (state, payload) => {
	state.agent = payload;
};

const UPDATE_VISIBLE_MODAL = (state, payload) => {
	state.visible = payload;
};

const UPDATE_VERSION_HISTORY = (state, payload) => {
	state.versions = payload;
};

const UPDATE_MODAL_LOADING = (state, payload) => {
	state.modalLoading = payload;
};

export default {
	UPDATE_SHOW_FORM,
	UPDATE_AGENT_LEADER,
	UPDATE_AGENT,
	UPDATE_USER,
	UPDATE_LOTTERY,
	UPDATE_AGENT_ID,
	UPDATE_LEADER,
	UPDATE_SERY_ID,
	UPDATE_AGENT_BY_SERIES,
	UPDATE_AGENT_CHECKED,
	UPDATE_TOTAL_USERS,
	UPDATE_COMPANY,
	UPDATE_SPINNING,
	UPDATE_SPIN,
	UPDATE_AGENT_IDS,
	UPDATE_CLEAR_FORM,
	UPDATE_VISIBLE_MODAL,
	UPDATE_VERSION_HISTORY,
	UPDATE_MODAL_LOADING,
	UPDATE_APPROVED_AGENTS
};
