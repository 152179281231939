<template>
	<a-col flex="auto" class="menu bg-white box-fade-in">
		<div
			class="page-header border-bottom flex items-center justify-between"
		>
			<div class="dbh-left flex items-center">
				<div class="dbh-total flex items-center">
					<div class="dbh-icon dbh-icon-agent">
						<fs-icon type="uil:users-alt"></fs-icon>
					</div>
					<div class="dbh-lebel">
						<p class="dbh-title">កូនកញ្ច្រែងសរុប</p>
						<div class="flex items-baseline">
							<h4 class="dbh-amount">{{ totalAgent.total }}</h4>
							<span class="dbh-amount-text">នាក់</span>
						</div>
					</div>
				</div>
				<div class="dbh-total flex items-center">
					<div class="dbh-icon dbh-icon-paper">
						<fs-icon type="ri:layout-masonry-fill"></fs-icon>
					</div>
					<div class="dbh-lebel">
						<p class="dbh-title">សរុបក្រដាស់ថ្ងៃនេះ</p>
						<div class="flex items-baseline">
							<h4 class="dbh-amount">
								{{ totalPaperToday.total }}
							</h4>
							<span class="dbh-amount-text">សន្លឹក</span>
						</div>
					</div>
				</div>
				<div class="dbh-total flex items-center">
					<div class="dbh-icon dbh-icon-agent-no-data">
						<fs-icon type="icon-park-outline:no-shooting"></fs-icon>
					</div>
					<div class="dbh-lebel">
						<p class="dbh-title">កូនកញ្រ្ចែងគ្មានទិន្ន័យថ្ងៃនេះ</p>
						<div class="flex items-baseline">
							<h4 class="dbh-amount">{{ totalAgentNoData }}</h4>
							<span class="dbh-amount-text">នាក់</span>
						</div>
					</div>
				</div>
				<div class="dbh-total flex items-center">
					<div class="dbh-icon dbh-icon-phone-data">
						<fs-icon type="carbon:application-mobile"></fs-icon>
					</div>
					<div class="dbh-lebel">
						<p class="dbh-title">ក្រដាស់សរុបខែមុន/ខែនេះ</p>
						<div class="flex items-baseline">
							<h4 class="dbh-amount">
								{{ totalPaper.last_month }} /
								{{ totalPaper.this_month }}
							</h4>
							<span class="dbh-amount-text pl-1"> សន្លឹក</span>
						</div>
					</div>
				</div>
			</div>
			<div class="dhb-right flex items-center">
				<a-dropdown>
					<a
						class="ant-dropdown-link flex items-center"
						@click="(e) => e.preventDefault()"
					>
						<a-avatar
							shape="square"
							:size="36"
							icon="user"
							:src="displayFlag(activeLang)"
						/>

						<i
							aria-label="icon: appstore"
							class="anticon anticon-appstore"
							name="icon"
						>
						</i>
					</a>
					<a-menu
						slot="overlay"
						class="-mt-0"
						style="width: 140px"
						v-if="locales"
					>
						<a-menu-item v-for="lang in locales" :key="lang">
							<a
								href="javascript:;"
								:class="[
									lang == activeLang ? 'bg-blue-50' : '',
								]"
								@click="handleChange(lang)"
							>
								<a-avatar
									shape="square"
									:size="24"
									icon="user"
									:src="displayFlag(lang)"
								/>

								<span class="pl-2">{{
									fullLanguageName(lang)
								}}</span></a
							>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
				<div class="logout-header ml-4" @click="handleLogout()">
					<fs-icon type="ri:logout-circle-r-line"></fs-icon>
				</div>
			</div>
		</div>
	</a-col>
</template>
<script>
	import Vue from 'vue';
	import { mapActions, mapGetters } from 'vuex';

	export default {
		name: 'MenuLayout',
		data() {
			return {
				activeLang: process.env.VUE_APP_DEFAULT_LANGUAGE,
				locales: [],
				langIcon: {
					kh: 'https://w7.pngwing.com/pngs/834/915/png-transparent-flag-of-cambodia-national-flag-khmer-flag-miscellaneous-flag-logo.png',
					en: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/2048px-United-kingdom_flag_icon_round.svg.png',
				},
				LangFullName: {
					kh: 'Khmer',
					en: 'English',
				},
			};
		},
		methods: {
			...mapActions(['logout']),
			...mapActions('dashboard', [
				'fetchTotalAgent',
				'fetchTotalPaperToday',
				'fetchTotalPaper',
				'fetchTotalagentNoData',
			]),
			handleChange(lang) {
				this.activeLang = lang;
				Vue.config.lang = lang;
				this.$cookies.set('locale', lang);
				this.$i18n.locale = Vue.config.lang;
				const html = document.documentElement;
				html.setAttribute('lang', lang);
			},
			fullLanguageName(lang) {
				return this.LangFullName[lang];
			},
			displayFlag(lang) {
				const defaultLocale = this.$cookies.get('locale');
				return lang
					? this.langIcon[lang]
					: this.langIcon[defaultLocale];
			},
			handleLogout() {
				const vm = this;
				this.$confirm({
					title: 'Do you want to logout?',
					content: '',
					async onOk() {
						await vm.doLogout();
					},
					onCancel() {},
				});
			},
			async doLogout() {
				await this.logout().then((response) => {
					let cookies = this.$cookies.keys();
					if (cookies) {
						cookies.forEach((cookie) => {
							if (cookie != 'locale')
								this.$cookies.set(cookie, '');
						});
					}
					this.$router.push({ name: 'admin.login' }).catch(() => {});
				});
			},
			async handleFetchTotalAgent() {
				await this.fetchTotalAgent();
			},
			async handleFetTotalPaperToday() {
				await this.fetchTotalPaperToday();
			},
			async handleFetTotalAgentNoData() {
				await this.fetchTotalagentNoData();
			},
			async handleFetTotalPaper() {
				await this.fetchTotalPaper();
			},
		},
		mounted() {
			this.activeLang = this.$i18n.locale;
			this.locales = this.$i18n.availableLocales;
			const html = document.documentElement;
			const lang = this.$cookies.get('locale');
			html.setAttribute('lang', lang);
			this.handleFetchTotalAgent();
			this.handleFetTotalPaperToday();
			this.handleFetTotalAgentNoData();
			this.handleFetTotalPaper();
		},
		computed: {
			...mapGetters('dashboard', [
				'totalAgent',
				'totalPaperToday',
				'totalPaper',
				'totalAgentNoData',
			]),
		},
	};
</script>
<style>
	.menu .ant-input-search > input {
		border-radius: 50px;
		padding-left: 24px;
		height: 46px;
	}
</style>
