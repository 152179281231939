const UPDATE_COMPONENT = (state, payload) => {
	state.component = payload;
};

const UPDATE_AGENT_LEADER = (state, payload) => {
	state.agentLeaders = payload;
};

const UPDATE_AGENT_LEADER_SPIN = (state, payload) => {
	state.agentLeaderSpin = payload;
};

const UPDATE_AGENT_LEADER_NAME = (state, payload) => {
	state.agentLeaderName = payload;
};

const UPDATE_START_DATE = (state, payload) => {
	state.startDate = payload;
};

const UPDATE_END_DATE = (state, payload) => {
	state.endDate = payload;
};

const UPDATE_REPORT = (state, payload) => {
	state.reports = payload;
};

const UPDATE_REPORT_SPIN = (state, payload) => {
	state.reportSpin = payload;
};

const UPDATE_COMPANY = (state, payload) => {
	state.companies = payload;
};

const UPDATE_COMPANY_NAME = (state, payload) => {
	state.companyName = payload;
};

const UPDATE_TOTAL_REPORT = (state, payload) => {
	state.totalReports = payload;
};

const UPDATE_SERIES = (state, payload) => {
	state.series = payload;
};

export default {
	UPDATE_COMPONENT,
	UPDATE_AGENT_LEADER,
	UPDATE_AGENT_LEADER_SPIN,
	UPDATE_AGENT_LEADER_NAME,
	UPDATE_START_DATE,
	UPDATE_END_DATE,
	UPDATE_REPORT,
	UPDATE_REPORT_SPIN,
	UPDATE_COMPANY,
	UPDATE_COMPANY_NAME,
	UPDATE_TOTAL_REPORT,
	UPDATE_SERIES
};
