const UPDATE_LOADING = (state, payload) => {
    state.loading = payload;
};
const GET_USER = (state, payload) => {
    state.userGroupWork = payload;
};

const UPDATE_GROUPWORK = (state, payload) => {
    state.groupWorks = payload;
};

const UPDATE_TEAMS = (state, payload) => {
    state.team = payload;
};

const UPDATE_GROUPMEMER = (state, payload) => {
    state.groupWorksMembers = payload;
};

export default {
    UPDATE_LOADING,
    GET_USER,
    UPDATE_GROUPWORK,
    UPDATE_TEAMS,
    UPDATE_GROUPMEMER,
};
