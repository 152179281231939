const UPDATE_SALARY_DRAWER = (state, payload) => {
    state.drawerVisible = payload;
};
const UPDATE_COMPONENT_TYPE = (state, payload) => {
    state.isCombine = payload;
};
const UPDATE_EMPLOYEES = (state, payload) => {
    state.employees = payload;
};
const UPDATE_COMPANY = (state, payload) => {
    state.company = payload;
};
const UPDATE_NODATE = (state, payload) => {
    state.nodate = payload;
};
const UPDATE_GRAPH = (state, payload) => {
    state.graph = payload;
};
const UPDATE_LIST = (state, payload) => {
    state.list = payload;
};
const UPDATE_SDATE = (state, payload) => {
    state.sDate = payload;
};
const UPDATE_EDATE = (state, payload) => {
    state.eDate = payload;
};
const UPDATE_ISVIEWER = (state, payload) => {
    state.isViewer = payload;
};
const UPDATE_MODAL_VIEWER = (state, payload) => {
    state.modal = payload;
};
const UPDATE_DATA_MODAL = (state, payload) => {
    state.dataModal = payload;
};
const UPDATE_SPIN_LIST = (state, payload) => {
    state.listSpiner = payload;
};

const UPDATE_SPIN_GRAPH = (state, payload) => {
    state.loadingGraph = payload;
};

const UPDATE_SPIN_USER = (state, payload) => {
    state.loadingUser = payload;
};

const UPDATE_EMP_TYPE = (state, payload) => {
    state.empType = payload;
};

export default {
    UPDATE_SALARY_DRAWER,
    UPDATE_COMPONENT_TYPE,
    UPDATE_EMPLOYEES,
    UPDATE_NODATE,
    UPDATE_GRAPH,
    UPDATE_LIST,
    UPDATE_SDATE,
    UPDATE_EDATE,
    UPDATE_COMPANY,
    UPDATE_ISVIEWER,
    UPDATE_MODAL_VIEWER,
    UPDATE_DATA_MODAL,
    UPDATE_SPIN_LIST,
    UPDATE_SPIN_GRAPH,
    UPDATE_SPIN_USER,
    UPDATE_EMP_TYPE
};
